import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./bookingTransactionsApi";

const initialState = {
  bookingTransactions: [],
};

export const bookingTransactionsSlice = createSlice({
  name: "bookingTransactions",
  initialState,
  reducers: {
    updateList(state, action) {
      state.bookingTransactions = action.payload;
    },
    created(state, action) {
      state.bookingTransactions.push(action.payload);
    },
  },
});

export const loadBookingTransactionsList = (from, to) => {
  return async (dispatch) => {
    return requestFromServer
      .getBookingTransactionsList(from, to)
      .then((res) => {
        dispatch(bookingTransactionsSlice.actions.updateList(res.data.data));
        //console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};
