import React from "react";
import { useParams } from "react-router-dom";
import { BookingsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BookingsPage() {
  const { status } = useParams();
  return (
    <CardBasic
      title={"Booking List"}
      btnType={"button"}
    >
      <BookingsTable status={status} />
    </CardBasic>
  );
}
