import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { BookingsPage } from "../pages/bookings/index";
import { BusinessCreate } from "../pages/business/create";
import { BookingCreate } from "../pages/bookings/create";
import { BannerPage } from '../pages/banner/index';
import { BannerCreate } from '../pages/banner/create';
import { BannerUpdate } from "../pages/banner/update";
import { ProfilePage } from "../pages/profile/index";
import { SegmentsPage } from "../pages/segment/index";
import { SegmentCreate } from "../pages/segment/create";
import { ServiceCategoriesPage } from "../pages/serviceCategories";
import { ServiceCategoryCreate } from "../pages/serviceCategories/create";
import { SegmentView } from "../pages/segment/view";
import { ServiceCategoryView } from "../pages/serviceCategories/view";
import { SubscriptionsPage } from "../pages/subscription";
import { SubscriptionCreate } from "../pages/subscription/create";
import { SubscriptionView } from "../pages/subscription/view";
import { TransactionPage } from "../pages/transaction";
import { BusinessUserPage } from "../pages/business";
import { CustomerPage } from "../pages/customers";
import { UsersCreate } from "../pages/userManagement/create";
import { UsersPage } from "../pages/userManagement";
import { UsersView } from "../pages/userManagement/view";
import { Dashboard } from "../pages/Dashboard";
import { BookingTransactionsPage } from '../pages/bookingTransactions/index';
import { SubscriptionTransactionsPage } from '../pages/subscriptionTransactions/index';
import { CouponsPage } from "../pages/coupons/index";
import { CouponCreate } from "../pages/coupons/create";
import { CouponView } from "../pages/coupons/view";
import { BookingCouponsPage } from "../pages/BookingCoupons/index";
import { BookingCouponCreate } from "../pages/BookingCoupons/create";
import { BookingCouponView } from "../pages/BookingCoupons/view";
import { LoyaltyPointsBusinessPages } from "../pages/loyaltyPoints/business/index";
import { LoyaltyPointBusinessCreate } from "../pages/loyaltyPoints/business/create";
import { LoyaltyPointsCustomerPages } from "../pages/loyaltyPoints/customer/index";
import { LoyaltyPointCustomerCreate } from "../pages/loyaltyPoints/customer/create";
import { BusinessDetailsPage } from "../pages/businessUser/index";
import { CustomerBookingPage } from "../pages/customerBooking/index";
import { BranchBookingPage } from "../pages/BranchBooking/index";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/bookings/:status?" component={BookingsPage} />
        <Route path="/booking/create" component={BookingCreate} />
        <Route exact path="/segments" component={SegmentsPage} />
        <Route exact path="/segment/create" component={SegmentCreate} />
        <Route exact path="/segment/:segmentUuid" component={SegmentView} />
        <Route exact path="/banners" component={BannerPage} />
        <Route exact path="/banner/create" component={BannerCreate} />
        <Route exact path="/banner/update/:uuid" component={BannerUpdate} />
        <Route exact path="/bookingTransactions" component={BookingTransactionsPage} />
        <Route exact path="/subscriptionTransactions" component={SubscriptionTransactionsPage} />
        <Route exact path="/coupons/subscription" component={CouponsPage} />
        <Route exact path="/coupons/subscription/create" component={CouponCreate} />
        <Route exact path="/coupons/subscription/:couponUuid" component={CouponView} />
        <Route exact path="/coupons/booking" component={BookingCouponsPage} />
        <Route exact path="/coupons/booking/create" component={BookingCouponCreate} />
        <Route exact path="/coupons/booking/:couponUuid" component={BookingCouponView} />
        <Route exact path="/business/loyaltyPoints/:email?" component={LoyaltyPointsBusinessPages} />
        <Route exact path="/business/loyaltyPoint/create" component={LoyaltyPointBusinessCreate} />
        <Route exact path="/customer/loyaltyPoints/:phone?" component={LoyaltyPointsCustomerPages} />
        <Route exact path="/customer/loyaltyPoint/create" component={LoyaltyPointCustomerCreate} />
        <Route exact path="/business/details/:ID" component={BusinessDetailsPage} />
        <Route exact path="/customer/booking/:PHONE" component={CustomerBookingPage} />
        <Route exact path="/branch/booking/:BID/:BB" component={BranchBookingPage} />
        <Route
          exact
          path="/service/categories"
          component={ServiceCategoriesPage}
        />
        <Route
          exact
          path="/service/category/create"
          component={ServiceCategoryCreate}
        />
        <Route
          exact
          path="/service/category/:categoryUuid"
          component={ServiceCategoryView}
        />
        <Route exact path="/subscriptions" component={SubscriptionsPage} />
        <Route
          exact
          path="/subscription/create"
          component={SubscriptionCreate}
        />
        <Route exact path="/subscription/:uuid" component={SubscriptionView} />
        <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/transaction" component={TransactionPage} />
        <Route exact path="/businesses" component={BusinessUserPage} />
        <Route exact path="/businesses/create" component={BusinessCreate} />
        <Route exact path="/customers" component={CustomerPage} />

        <Route exact path="/users" component={UsersPage} />
        <Route exact path="/user/create" component={UsersCreate} />
        <Route exact path="/user/:uuid" component={UsersView} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
