/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import ResetPasswordDialog from "../Dialogs/ResetPasswordDialog";
import ExtendDurationDialog from "../Dialogs/ExtendDurationDialog";
import ChangePhoneNumberDialog from "../Dialogs/ChangePhoneNumberDialog";

export const BusinessDropDownMenu = (props) => {
  console.log(props);
  return (
    <>
      <ul className="navi flex-column navi-hover py-2">
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-text">
              <ResetPasswordDialog
                content="Reset Password"
                businessDetails={props}
              />
            </span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-text">
              <ChangePhoneNumberDialog
                content="Change Phone Number"
                businessDetails={props}
              />
            </span>
          </a>
        </li>

        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-text">
              <ExtendDurationDialog
                content="Extend & Upgrade"
                businessDetails={props}
              />
            </span>
          </a>
        </li>
      </ul>
    </>
  );
};
