import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../../_metronic/_helpers";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";

import paginationFactory from "react-bootstrap-table2-paginator";

import { useDispatch, useSelector } from "react-redux";
import { loadBusinessLoyaltyPointsList } from "../../../store/admin/loyaltyPoints/businessLoyaltyPointsApiSlice";
import { getBusinessLoyaltyPointsList } from "../../../store/admin/loyaltyPoints/businessLoyaltyPointsApi";
import CouponDeleteDialog from "../../../components/Dialogs/CouponDeleteDialog";

export const LoyaltyPointsTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [wallet, setWallet] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const { email } = useParams();
  // console.log(email);
  // console.log(data);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
  });

  useEffect(() => {
    if (email != undefined) {
      getBusinessLoyaltyPointsList({ email: email }).then((res) => {
        //console.log(res);
        if (res.data.data.Loyalty_Points.length > 0) {
          setData(res.data.data.Loyalty_Points);
          setWallet(res.data.data.Wallet_Details);
        } else {
          setData(res);
          setErrorLoading(true);
        }
        setDataLoading(false);
      });
    }
  }, [email]);

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const LinkFormatter = (cell, row) => {
    return <span>{cell.charAt(0).toUpperCase() + cell.slice(1)}</span>;
  };

  const TypeFormatter = (cell, row) => {
    if (row.type === "debit") {
      return (
        <p>
          <span
            style={{
              color: "red",
              fontWeight: "700",
              fontSize: "14px",
              marginRight: "5px",
            }}
          >
            -
          </span>
          {cell}
        </p>
      );
    }
    return (
      <p>
        <span
          style={{ fontWeight: "700", fontSize: "14px", marginRight: "5px" }}
        >
          +
        </span>
        {cell}
      </p>
    );
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index + 1,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: TypeFormatter,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   formatter: LinkFormatter,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      //search
      keyField="uuid"
      data={data}
      columns={columns}
      autoValue
    >
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            {/* <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              /> */}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, errors) => {
                //console.log(values);
                setDataLoading(true);
                dispatch(loadBusinessLoyaltyPointsList(values))
                  .then((response) => {
                    //console.log(response);
                    if (response.Loyalty_Points.length > 0) {
                      setData(response.Loyalty_Points);
                      setWallet(response.Wallet_Details);
                    } else {
                      setData(response);
                      setErrorLoading(true);
                    }
                    setDataLoading(false);
                    //handleClose();
                  })
                  .catch((err) => {
                    //console.log(err.message);
                    setErrorLoading(true);
                  });
              }}
            >
              {({ values, errors, touched, handleChange }) => {
                // console.log(values);
                return (
                  <Form className="container">
                    <div className="d-flex justify-content-center">
                      {/* <h4 style={{ margin: "0" }}>Enter Coupon Code</h4> */}
                      <div className="mr-5">
                        <TextField
                          name="email"
                          type="text"
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email && touched.email}
                          helperText={touched.email && errors.email}
                          //className={classes.textField}
                          style={{
                            borderRadius: " 10px 0px 0px 10px",
                            height: "56px",
                            width: "220px",
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn bg-primary font-weight-bold text-white"
                          type="submit"
                          // onClick={() => {}}
                          style={{
                            borderRadius: "10px",
                            width: "100px",
                            height: "50px",
                            padding: "5px",
                            lineHeight: "16px",
                            fontSize: "12px",
                          }}
                        >
                          Show Loyalty Points
                        </button>
                      </div>
                    </div>
                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </Form>
                );
              }}
            </Formik>
            {/* </div> */}
            <div className="container mt-10">
              <div class="d-flex justify-content-center">
                <h4 className="mb-5 float-right">
                Loyalty Points{" "}
                  <span
                    style={{
                      padding: "8px",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    {wallet.balance ? wallet.balance : '0'}
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            insertRow
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>
                  {!dataLoading && !errorLoading
                    ? "Enter Email Id to view Business Loyalty Points"
                    : dataLoading
                    ? "Loading Data..."
                    : "No records found"}
                </h3>
                {/* <h3>{dataLoading || !dataLoading && errorLoading ?  "Loading Data..." : "No records found"}</h3> */}
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
