import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";

import { Link } from "react-router-dom";
import { loadBannerList } from '../../store/admin/banner/bannerSlice';
import BranchImageDeleteDialog from '../../components/Dialogs/BranchImageDeleteDialog';

export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];

const LoadImage = (cell, row) => {
  //console.log(cell);
  return (
    <img src={`${process.env.REACT_APP_MEDIA_URL}/${cell}`} height="100px" width="auto"></img>
  );
};

const CityDisplay = (cell, row) => {
  //console.log(cell);
  if(cell !== null && cell.length > 0) {
    return <p>{cell.toString()}</p>
  } else  {
    return (
      <p>N/A</p>
    );
  }

};

const ActionDisplay = (cell, row) => {
  // console.log(row.uuid);
  // console.log(cell);
  return <BranchImageDeleteDialog content={<i className="flaticon-delete icon-xl" type="button" style={{ cursor: "pointer" }} />} uuid={row.uuid} />
 // return <Link to={`branch/${row.uuid}/addImage`}><CloudUploadIcon /></Link>
};

export const BannerTable = () => {
  const dispatch = useDispatch();

  const bannerData = useSelector((state) => state.banner.banners);
  //console.log(bannerData);
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadBannerList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const NameFormatter = (cell, row) => {
    // console.log("here ",cell,row);
    if(cell === 1) {
        return  <Link className="text-uppercase" to={`/banner/update/${row.uuid}`}>
        <span className="text-uppercase">Banner 1</span>
      </Link>;
    } else if (cell === 2) {
      return <Link className="text-uppercase" to={`/banner/update/${row.uuid}`}>
      <span className="text-uppercase">Banner 2</span>
    </Link>;;
    } else if (cell === 3) {
      return <Link className="text-uppercase" to={`/banner/update/${row.uuid}`}>
      <span className="text-uppercase">Banner 3</span>
    </Link>;;
    } else {
      return "";
    }
  };

  const columns = [
    {
      dataField: "bannerName",
      text: "Banner Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: NameFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
      sortCaret: sortCaret,
      formatter: LoadImage,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      sortCaret: sortCaret,
      formatter: CityDisplay,
    },
    {
      dataField: "",
      text: "Delete Image",
      formatter: ActionDisplay,
    },
  ];

  // const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="_id" data={bannerData} columns={columns}>
      {(props) => (
        <>
          {/* <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div> */}

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
