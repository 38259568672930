import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, useParams } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { businessUserList } from "../../store/admin/business/businessApi";
import dayjs from "dayjs";

export const BusinessDetailsTable = () => {
  const { ID } = useParams();
  console.log(ID)
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.user.user);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    businessUserList({ businessUuid: ID }).then((res) => {
        console.log("res",res.data.data);
        setData(res.data.data);

        setDataLoading(false);
      }).catch((err) => {
        console.log(err)
      });
  }, [ID]);

  // const linkFormatter = (cell, row) => {
  //   //console.log(row);
  //     return (
  //       <Link className="text-uppercase" to={`/customer/loyaltyPoints/${row.phone}`}>
  //         <span className="text-uppercase">{cell}</span>
  //       </Link>
  //     );
  // };

  // const DateFormatter = (cell, row) => {
  //   return `${dayjs(`${cell}`).format("DD-MM-YYYY")}`;
  // };

  const linkFormatter2 = (cell, row) => {
    console.log("row cell",row,cell);
       return (
         <Link className="text-uppercase" to={`/branch/booking/${row.branchData.uuid}/upcoming`}>
           <span className="text-uppercase">{cell}</span>
         </Link>
       );
   };
   const linkFormatter3 = (cell, row) => {
    // console.log(row);
       return (
         <Link className="text-uppercase" to={`/branch/booking/${row.branchData.uuid}/canceled`}>
           <span className="text-uppercase">{cell}</span>
         </Link>
       );
   };
   const linkFormatter4 = (cell, row) => {
    // console.log(row);
       return (
         <Link className="text-uppercase" to={`/branch/booking/${row.branchData.uuid}/completed`}>
           <span className="text-uppercase">{cell}</span>
         </Link>
       );
   };

  const myData = [
    {
      businessName: "dsdsds",
      email: "dsdsds@gmail.com",
      phone: "856565623232",
      up: 5,
      com: 10,
      canc: 50
    },
    {
      businessName: "xcxcxs",
      email: "mnmn@gmail.com",
      phone: "5656565623232",
      up: 7,
      com: 20,
      canc: 65
    }
  ]

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index+1,
    },
    {
      
      dataField: "branchData.name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "branchData.phone",
      text: "Phone",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "branchData.email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "bookingData.pending",
      text: "Upcoming Booking",
      formatter: linkFormatter2,
    },
    {
      dataField: "bookingData.complete",
      text: "Completed Booking",
      formatter: linkFormatter4
    },
    {
      dataField: "bookingData.cancel",
      text: "Cancelled Booking",
      formatter: linkFormatter3
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    
    <ToolkitProvider search keyField="uuid" data={data} columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
