/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {
  getSegmentData,
  getServiceCategoryData,
} from "../store/data/dataSlice";

export function Routes() {
  const { token, authenticated, user } = useSelector(
    (state) => state.authPersist
  );

  console.log(authenticated, token);
  // const { isAuthorized } = useSelector(
  //   ({ auth }) => ({
  //     isAuthorized: auth.user != null,
  //   }),
  //   shallowEqual
  // );

  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticated) {
      dispatch(getSegmentData())
        .then(() => {
          console.log("Segments Loaded");
        })
        .catch((err) => {
          console.log(err.message);
        });

      dispatch(getServiceCategoryData())
        .then(() => {
          console.log("Service Categories Loaded");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [authenticated]);

  return (
    <Switch>
      {!authenticated ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!authenticated ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
