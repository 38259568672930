import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { SubscriptionTransactionsTable } from "./table";

export function SubscriptionTransactionsPage() {
  return (
    <CardBasic
      title={"List of Subscription Transactions"}
      btnType={"button"}
    >
      <SubscriptionTransactionsTable />
    </CardBasic>
  );
}
