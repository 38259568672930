import React from "react";
import { useParams } from "react-router-dom";
import { CustomerBookingTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function CustomerBookingPage() {
  const { status } = useParams();
  return (
    <CardBasic
      title={"Customer Booking List"}
      btnType={"button"}
    >
      <CustomerBookingTable status={status} />
    </CardBasic>
  );
}
