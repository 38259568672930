import React from "react";
import { useParams } from "react-router-dom";
import { BranchBookingTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BranchBookingPage() {
  const { status } = useParams();
  return (
    <CardBasic
      title={"Branch Booking List"}
      btnType={"button"}
    >
      <BranchBookingTable status={status} />
    </CardBasic>
  );
}
