import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
export const UsersCreate = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [formStatus, setFormStatus] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    isActive: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    role: Yup.string().required("Type is required"),
    // password: Yup.string()
    //   .min(6, "Password should have minimum 6 characters")
    //   .required("This field is required"),
    // confirmPassword: Yup.string()
    //   .min(6, "Password should have minimum 6 characters")
    //   .required("This field is required")
    //   .when("password", {
    //     is: (val) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       "Credentials do not match"
    //     ),
    //   }),
  });
  return (
    <CardBasic
      title={"Add User"}
      btnType={"submit"}
      formId={"createRolesForm"}
      headerBtnText={"Save User"}
    >

      <Formik
        initialValues={{
          name: "",
          phone: "",
          isActive: false,
          email: "",
          role: "",
          // confirmPassword: "",
          // password: "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {}}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createRoleForm">
            <div className="form-group row">
              <div className="col-6">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Name"
                  label="Name *"
                />
              </div>
              <div className="col-6">
                <Field
                  name="contactNumber"
                  component={Input}
                  placeholder="Contact number"
                  label="Contact Number *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="email"
                  component={Input}
                  placeholder="Email"
                  label="Email ID *"
                />
              </div>
              <div className="col-lg-6">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
            {/* <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  type="password"
                  name="password"
                  component={Input}
                  placeholder="New Password"
                  label="New Password *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type="password"
                  name="confirmPassword"
                  component={Input}
                  placeholder="Confirm Password"
                  label="Confirm Password *"
                />
              </div>
            </div> */}

            <div className="form-group row">
              <div className="col-lg-12">
                <Select name="role" label="Role *">
                  <option value="admin">Administrator</option>
                  <option value="manager">Manager</option>
                </Select>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
