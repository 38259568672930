import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, Input } from "../../_metronic/_partials/controls";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import ClearIcon from "@material-ui/icons/Clear";
import { businessResetPassword } from "../../store/admin/business/businessApi";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

export default function ResetPasswordDialog({ content, svg, businessDetails }) {
  //console.log(businessDetails);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const NewPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password.")
  });

  const submitHandler = (values) => {
    //console.log(values);
    dispatch(businessResetPassword(values)
      .then((response) => {
        console.log(response);
          dispatch(sendSuccess("Password reset successfully"))
        handleClose();
      })
      .catch((err) => {
        dispatch(sendFailure(err.message));
        console.log(err.message);
      }));
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //console.log(values);
      const newValue = { ...values, businessUuid: businessDetails.status.uuid };
      //console.log(newValue);
      setSubmitting(true);
      submitHandler(newValue);
      setSubmitting(false);
    },
  });

  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-title"
          className="d-flex justify-content-end align-items-center bg-primary text-white mb-5 w-100 pt-4 pb-4"
        >
          <div
            className="d-flex justify-content-between flex-row align-items-center pr-4 pl-5"
            style={{ width: "70%" }}
          >
            <h2>{content}</h2>
            <ClearIcon
              className="display-4"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="flex align-items-center">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <div className="form-group fv-plugins-icon-container">
                <h6>New Password</h6>
                <input
                  placeholder="New Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="newPassword"
                  {...formik.getFieldProps("newPassword")}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newPassword}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <h6>Confirm Password</h6>
                <input
                  placeholder="Confirm Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "confirmPassword"
                  )}`}
                  name="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.confirmPassword}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                {/* <Link to="/auth"> */}
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                //disabled={formik.isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
