import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { BookingTransactionsTable } from "./table";

export function BookingTransactionsPage() {
  return (
    <CardBasic
      title={"List of Booking Transactions"}
      btnType={"button"}
    >
      <BookingTransactionsTable />
    </CardBasic>
  );
}
