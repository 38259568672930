import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, Input } from "../../_metronic/_partials/controls";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import ClearIcon from "@material-ui/icons/Clear";
import { businessPhoneChange } from "../../store/admin/business/businessApi";
import { updateBusinessData } from "../../store/admin/business/businessSlice";

export default function ChangePhoneNumberDialog({
  content,
  svg,
  businessDetails,
}) {
  //console.log(businessDetails);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    oldPhone: businessDetails.status.phone,
    oldCountryCode: businessDetails.status.countryCode,
    phone: "",
    countryCode: "+91",
    businessUuid: businessDetails.status.uuid,
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    countryCode: Yup.string(),
  });

  const submitHandler = (values) => {
    //console.log(values);
    dispatch(
      businessPhoneChange(values)
        .then((response) => {
          //console.log(response);
          dispatch(updateBusinessData(response.data.data));
          dispatch(sendSuccess("Password reset successfully"));
          handleClose();
        })
        .catch((err) => {
          dispatch(sendFailure(err.message));
          //console.log(err.message);
        })
    );
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //console.log(values);
      delete values.oldPhone;
      delete values.oldCountryCode;
      setSubmitting(true);
      submitHandler(values);
      setSubmitting(false);
    },
  });

  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-title"
          className="d-flex justify-content-end align-items-center bg-primary text-white mb-5 w-100 pt-4 pb-4"
        >
          <div
            className="d-flex justify-content-between flex-row align-items-center pr-4 pl-5"
            style={{ width: "75%" }}
          >
            <h2>{content}</h2>
            <ClearIcon
              className="display-4"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="flex align-items-center">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <div className="form-group fv-plugins-icon-container row">
                <select
                  placeholder="Country Code"
                  disabled
                  type="text"
                  className={`form-control form-control-solid h-auto py-2 px-3 col-lg-2 ml-4 mr-4`}
                  name="countryCode"
                  {...formik.getFieldProps("oldCountryCode")}
                >
                  {/* <option value="">Country Code</option> */}
                  <option value="+91" selected>
                    +91
                  </option>
                  <option value="+971">+971</option>
                </select>

                <input
                  placeholder="Phone"
                  type="oldPhone"
                  className={`form-control form-control-solid h-auto py-5 px-6 col-lg-9`}
                  disabled
                  name="oldPhone"
                  //maxLength="10"
                  {...formik.getFieldProps("oldPhone")}
                />
              </div>
              <div className="form-group fv-plugins-icon-container row">
                <select
                  placeholder="Country Code"
                  type="text"
                  className={`form-control form-control-solid h-auto py-2 px-3 col-lg-2 ml-4 mr-4 ${getInputClasses(
                    "countryCode"
                  )}`}
                  name="countryCode"
                  {...formik.getFieldProps("countryCode")}
                >
                  {/* <option value="">Country Code</option> */}
                  <option value="+91" selected>
                    +91
                  </option>
                  <option value="+971">+971</option>
                </select>

                <input
                  placeholder="New Phone Number"
                  type="phone"
                  className={`form-control form-control-solid h-auto py-5 px-6 col-lg-9 ${getInputClasses(
                    "phone"
                  )}`}
                  name="phone"
                  //maxLength="10"
                  {...formik.getFieldProps("phone")}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div
                    className="fv-plugins-message-container"
                    style={{ marginLeft: "120px" }}
                  >
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                {/* <Link to="/auth"> */}
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  //disabled={formik.isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
