import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerBasic } from "../components/spinners/SpinnerBasic";
import { BarChart } from "../components/widgets/BarChart";
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {
  getPlatformReport,
  getBusinessSalesReport
} from "../store/admin/reports/reportApi";
import { getCountryCodeList } from "../store/countryCode/countryCodeSlice";
import { TilesWidget11 } from "../_metronic/_partials/widgets";
// import { BaseTablesWidget11, BaseTablesWidget12 } from '../_metronic/_partials/widgets';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FCFCFC',
  },
  appBar: {
    boxShadow: 'none ',
    backgroundColor: '#fff',
    borderBottom: '1px solid #cdcdcd',
    color: '#339187',
  },
  tabs: {
    color: '#339187',
  },
  tab: {
    color: '#339187',
    fontSize: '15px'
    // indicatorColor: '#000',
    // '&:focus': {
    //   borderBottom: '2px solid #00534a',
    // }
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

}));

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const [businessReportData, setBusinessReportData] = useState({});
  const [salesData, setSalesData] = useState([]);
  const [topBranch, setTopBranch] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [businessSalesReport, setBusinessSalesReport] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const [dateFilter, setDateFilter] = React.useState(3);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  console.log(businessReportData)
  useEffect(() => {
      dispatch(getCountryCodeList());
  }, []);

  const last15Day = new Date();
  last15Day.setDate(last15Day.getDate() - 15);

  const [date, setDate] = React.useState({from: new Date(), to: last15Day});

  const handleDateChange = (e) => {
    setDateFilter(e);
    const last7Day = new Date();
    last7Day.setDate(last7Day.getDate() - 7);
    //console.log(last7Day);
  
    const last15Day = new Date();
    last15Day.setDate(last15Day.getDate() - 15);
    //console.log(last15Day);
  
    const last30Day = new Date();
    last30Day.setDate(last30Day.getDate() - 30);
    //console.log(last30Day);
  
    const last1Year = new Date();
    last1Year.setDate(last1Year.getDate() - 365);
    //console.log(last1Year);

    const last2Year = new Date();
    last2Year.setDate(last2Year.getDate() - 730);

    if(e === 1) {
      setDate({from: new Date(), to: new Date()});
    } else if (e === 2) {
      setDate({from: new Date(), to: last7Day});
    }
    else if (e === 3) {
      setDate({from: new Date(), to: last15Day});
    }
    else if (e === 4) {
      setDate({from: new Date(), to: last30Day});
    } else if (e === 5) {
      setDate({from: new Date(), to: last1Year});
    } else {
      setDate({from: new Date(), to: last2Year});
    }
  }

  //console.log(role);
  useEffect(() => {
    getPlatformReport().then((response) => {
      //console.log(response);
      setBusinessReportData(response.data.data);
      getBusinessSalesReport(date).then((response) => {
        //console.log(response.data.data);
        let salesData = response.data.data;
        // setSalesData([
        //   salesData.last7days.length > 0
        //     ? salesData.last7days[0].totalRevenue
        //     : 0,
        //   salesData.last30days.length > 0
        //     ? salesData.last30days[0].totalRevenue
        //     : 0,
        //   salesData.last365days.length > 0
        //     ? salesData.last365days[0].totalRevenue
        //     : 0,
        // ]);
        //console.log(salesData);
        setDataLoading(false);
      });
    });
  }, []);

  // useEffect(() => {
  //   getTopServices().then((response) => {
  //     //console.log(response.data.data);
  //     setTopServices(response.data.data);
  //   });
  // }, []);

  // useEffect(() => {
  //   getTopPackages().then((response) => {
  //     //console.log(response.data.data);
  //     setTopPackages(response.data.data);
  //   });
  // }, []);

  // useEffect(() => {
  //   getBusinessReportBranch().then((response) => {
  //     //console.log(response.data.data);
  //     setBranchReport(response.data.data);
  //   });
  // }, []);

  return (
    <>
      <>
        {dataLoading ? (
          <SpinnerBasic />
        ) : (
          <>
            {/* <div className="container">
              <div className="row" style={{ justifyContent: "right" }}>
                <div className="p-5">
                  <Select id="fruits" value={dateFilter} onChange={(e) => handleDateChange(e.target.value)} style={{ width: "150px", textAlign: "center", border: "1px solid #339187" }} >
                    <option value={1} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Today</option>
                    <option value={2} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 7 Days</option>
                    <option value={3} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 15 Days</option>
                    <option value={4} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 30 Days</option>
                    <option value={5} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 1 Year</option>
                    <option value={6} style={{ textAlign: "center", padding: "5px", cursor: 'pointer' }} >Last 2 Year</option>
                  </Select>
                </div>
              </div> */}

              <div className="row">
                {/* Bookings Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.Booking.Total ?? 0}
                        title="Total Bookings"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        //linkUrl="/bookings/upcoming"
                      />
                    </div>
                    <div className="col-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.Booking.Total ?? 0}
                        title="Upcoming"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl="/bookings/upcoming"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.Booking.Complete ?? 0}
                        title="Completed"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl="bookings/completed"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        count={businessReportData.Booking.Cancelled ?? 0}
                        title="Cancelled"
                        svgUrl="/media/svg/icons/Shopping/Cart3.svg"
                        linkUrl="/bookings/cancelled"
                      />
                    </div>
                  </div>
                </div>
                {/* Service & Package Report */}
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.Client.Active ?? 0}
                        title="Customers"
                        svgUrl="/media/svg/icons/General/User.svg"
                        linkUrl="/customers"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.Businesses.Active ?? 0}
                        title="Businesses"
                        svgUrl="/media/svg/icons/Home/Building.svg"
                        linkUrl="/Businesses"
                      />
                    </div>
                    <div className="col-sm-6">
                      <TilesWidget11
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                        count={businessReportData.Branch.Active ?? 0}
                        title="Branches"
                        svgUrl="/media/svg/icons/Map/Marker1.svg"
                        linkUrl="#"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="row">
                    <div className="col-12">
                      <TilesWidget11
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                        //count={`${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'} ${businessReportData.TotalRevenue ?? 0}`}
                        count={currencySymbol[0] && businessReportData.TotalRevenue ? `${currencySymbol[0].currencySymbol} ${businessReportData.TotalRevenue}` : `₹ 0`}
                        title="Total Revenue"
                        svgUrl="/media/svg/icons/Shopping/Chart-bar1.svg"
                        linkUrl="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-xl-6">
                  <BarChart
                    className="gutter-b card-stretch"
                    chartColor="danger"
                    data={salesData}
                  />
                </div>
                <div className="col-xl-6">
                  <BaseTablesWidget12
                    className="gutter-b card-stretch"
                    chartColor="danger"
                    title="branch"
                    data={branchReport}
                  />
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-xl-6">
                  <BaseTablesWidget11
                    className="gutter-b card-stretch"
                    title="service"
                  // chartColor="danger"
                    data={topServices}
                  />
                </div>
                <div className="col-xl-6">
                  <BaseTablesWidget11
                    className="gutter-b card-stretch"
                    title="package"
                  // chartColor="danger"
                    data={topPackages}
                  />
                </div>
              </div> */}
            {/* </div> */}
          </>
        )}
      </>
    </>
  );
};
