import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceCategoriesTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function ServiceCategoriesPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  //console.log(user);
  return (
    <CardBasic
      title={"Services Categories List"}
      btnType={user.countryCode === 91 ? "button" : ""}
      headerBtnText={"New Service Category"}
      headerBtnLink={"/service/category/create"}
    >
      <ServiceCategoriesTable />
    </CardBasic>
  );
}
