import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";

import { useDispatch, useSelector } from "react-redux";
import { loadBookingCouponsList } from "../../store/admin/bookingCoupon/couponSlice";
import BookingCouponDeleteDialog from '../../components/Dialogs/BookingCouponDeleteDialog';
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

export const SubscriptionTable = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bookingCoupon.bookingCoupons);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  console.log(data)
  const [dataLoading, setDataLoading] = useState(true);
    console.log(data);
  useEffect(() => {
    dispatch(getCountryCodeList());
    dispatch(loadBookingCouponsList()).then((res) => {
      //console.log(data);
      setDataLoading(false);
    });
  }, []);

  const ActionDisplay = (cell, row) => {
    console.log(row.uuid);
    console.log(cell);
    return <BookingCouponDeleteDialog content={<i className="flaticon-delete" type="button" style={{ cursor: "pointer" }} />} uuid={row.uuid} />
   // return <Link to={`branch/${row.uuid}/addImage`}><CloudUploadIcon /></Link>
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const LinkFormatter = (cell, row) => {
    return <Link to={`/coupons/booking/${row.uuid}`}>{cell}</Link>;
  };
  //TO DO 
  const TypeFormatter = (cell, row) => {
    console.log(cell)
    if (cell === 1) {
      return "Percentage";
    } else {
      return "Flat";
    }
  };
  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index+1,
    },
    {
      dataField: "couponCode",
      text: "Coupon Code",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "discountType",
    //   text: "Discount Type",
    //   sort: true,
    //   formatter: TypeFormatter,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "minimumAmount",
      text: `Minimum Amount (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹' })`,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "startDate",
      text: "Start Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "expiryDate",
      text: "Expiry Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "",
      text: "Action",
      formatter: ActionDisplay,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="uuid"
      data={data}
      columns={columns}
      autoValue
    >
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              {/* <h4 className="mb-0 float-right">Search</h4> */}
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            insertRow
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
