import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./bookingApi";

const initialState = {
  currentCustomer: "",
  bookings: [],
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    updateList(state, action) {
      state.bookings = action.payload;
    }
  },
});

export const loadBookingList = (from, to) => {
  return async (dispatch) => {
    return requestFromServer
      .getBookingsList(from, to)
      .then((response) => {
        console.log(response.data);
        dispatch(bookingSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};