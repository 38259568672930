import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./couponApi";

const initialState = {
  bookingCoupons: [],
};

export const bookingCouponsSlice = createSlice({
  name: "bookingCoupons",
  initialState,
  reducers: {
    updateList(state, action) {
      state.bookingCoupons = action.payload;
    },
    created(state, action) {
      state.bookingCoupons.push(action.payload);
    },
    updateCouponList(state, action) {
      // state.branch.image.push(action.payload);
      // const key = state.branch.image;
      // console.log(key);
      const CoponIndex = state.bookingCoupons.findIndex(
        (el) => el.uuid === action.payload
      );
      console.log(CoponIndex);
      if (CoponIndex > -1) {
        state.bookingCoupons.splice(CoponIndex, 1);
      }
    },
  },
});

export const loadBookingCouponsList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCouponList()
      .then((res) => {
        console.log(res.data.data);
        dispatch(bookingCouponsSlice.actions.updateList(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};

export const createBookingCoupon = (data) => {
  return async (dispatch) => {
    console.log(data);
    return requestFromServer
      .createCoupon(data)
      .then((response) => {
        console.log(response);
        dispatch(bookingCouponsSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        throw new Error(error.response.data.error);
      });
  };
};

export const updateBookingCouponeDeleteData = (uuid) => {
  console.log(uuid);
  return async (dispatch) => {
    dispatch(bookingCouponsSlice.actions.updateCouponList(uuid));
  };
};