import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { useDispatch, useSelector } from "react-redux";
import { createBusiness } from "../../store/admin/business/businessSlice";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "../../_metronic/_partials/controls/forms/MultiSelect";
import { TimePickerField } from "../../components/pickers/TimePickerField";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

export const BusinessCreate = () => {
  // Validation schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const history = useHistory();
  const dispatch = useDispatch();

  const { segments } = useSelector((state) => state.data);
  const { countryCode } = useSelector((state) => state.countryCode);
  const { calenderType } = useSelector((state) => state.countryCode);
  // console.log(countryCode);
  // console.log(calenderType);
  const validationSchema = Yup.object().shape({
    business_name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    owner_name: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    c_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please enter your password."),
    calendarType: Yup.string().required("Type is required"),
    //countryCode: Yup.number().required("This field is required"),
  });

  useEffect(() => {
    if (countryCode.length === 0) {
      dispatch(getCountryCodeList());
    }
  }, []);

  return (
    <CardBasic
      title={"Add New Business"}
      btnType={"submit"}
      formId={"createBusinessForm"}
      headerBtnText={"Save Business"}
    >
      <Formik
        initialValues={{
          business_name: "",
          owner_name: "",
          phone: "",
          email: "",
          password: "",
          c_password: "",
          calendarType: "",
         // countryCode: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          dispatch(createBusiness(values))
            .then(() => {
              dispatch(
                sendSuccess("The business has been created successfully")
              );
              history.replace("/businesses");
            })
            .catch((err) => {
              console.log(err);
              dispatch(sendFailure("Error! Duplicate Segment Name."));
              actions.setStatus("Error! Duplicate Segment Name.");
              console.log(err.message);
            });
          //actions.setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="createBusinessForm">
            <div className="form-group row">
              {/* Branch Name */}
              <div className="col-lg-6">
                <Field
                  name="business_name"
                  component={Input}
                  placeholder="Business Name"
                  label="Business Name *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="owner_name"
                  component={Input}
                  placeholder="Owner Name"
                  label="Owner Name *"
                />
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="calendarType" label="Calendar Type *">
                  {/* <option value="">Calendar Type</option>
                  <option value="shift">Shift</option>
                  <option value="schedule">Schedule</option> */}
                  <option
                    value={"Select Calender Type"}
                    key={"Select Calender Type"}
                  >
                    {"Select Calender Type"}
                  </option>
                  {calenderType.map((calenderType, index) => (
                    <option value={calenderType} key={calenderType}>
                      {calenderType}
                    </option>
                  ))}
                </Select>
              </div>
              {/* <div className="col-lg-1">
                <Select name="countryCode" label="Code *">
                  <option value="+91" selected>+91</option>
                  <option value="+971">+971</option>
                  <option
                    value={"Select Country Code"}
                    key={"Select Country Code"}
                  >
                    {"Select Country Code"}
                  </option>
                  {countryCode.map((countryCode, index) => (
                    <option
                      value={countryCode.countryCode}
                      key={countryCode.countryCode}
                    >
                      {countryCode.countryCode}
                    </option>
                  ))}
                </Select>
              </div> */}
              <div className="col-lg-4">
                <Field
                  name="phone"
                  component={Input}
                  placeholder="9899898893"
                  label="Phone Number *"
                  //maxLength="10"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Email Address *"
                />
              </div>
            </div>
            {/* password */}
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="Password *"
                  maxLength="10"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="c_password"
                  type="password"
                  component={Input}
                  placeholder=""
                  label="Confirm Password *"
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify(formProps.values, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
