import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { BusinessDetailsTable } from "./table";

export function BusinessDetailsPage() {
  return (
    <CardBasic
      title={"Business Details"}
      btnType={"button"}
    >
      <BusinessDetailsTable />
    </CardBasic>
  );
}
