import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/admin/booking";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBookingsList = (data) => {
  const req = {
    bookingStatus: data.bookingStatus,
    from: data.from,
    to: data.to,
  };
  console.log(req);
  return axios.post(`${URL}/listByDate`,  req, {
    headers,
  });
};