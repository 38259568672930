import React from "react";

import { SubscriptionTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BookingCouponsPage() {
  return (
    <CardBasic
      title={"Booking Coupons"}
      btnType={"button"}
      headerBtnText={"Add Booking Coupon"}
      headerBtnLink={"/coupons/booking/create"}
    >
      <SubscriptionTable />
    </CardBasic>
  );
}
