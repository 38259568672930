// import {all} from "redux-saga/effects";
import { combineReducers } from "redux";

import otpReducer from "../store/otp/otpSlice";
import { authSlice } from "../store/business/authSlice";
import { authPersist } from "../store/business/authSlice";
import { segmentSlice } from "../store/admin/segment/segmentSlice";
import { categorySlice } from "../store/admin/category/categorySlice";
import { dataSlice } from "../store/data/dataSlice";
import { subscriptionSlice } from "../store/admin/subscription/subscriptionSlice";
import { businessSlice } from "../store/admin/business/businessSlice";
import {customerSlice} from "../store/admin/customer/customerSlice"
import { toastSlice } from "../store/toast/toastSlice";
import { bookingSlice } from "../store/admin/bookings/bookingSlice";
import { bannerSlice } from "../store/admin/banner/bannerSlice";
import { bookingTransactionsSlice } from '../store/admin/bookingTransactions/bookingTransactionsSlice';
import { subscriptionTransactionsSlice } from '../store/admin/subscriptionTransactions/subscriptionTransactionsSlice';
import { couponsSlice } from '../store/admin/coupon/couponSlice';
import countryCodeReducer from '../store/countryCode/countryCodeSlice';
import { bookingCouponsSlice } from '../store/admin/bookingCoupon/couponSlice';

export const rootReducer = combineReducers({
  otp: otpReducer,
  auth: authSlice.reducer,
  authPersist: authPersist,
  segments: segmentSlice.reducer,
  categories: categorySlice.reducer,
  data: dataSlice.reducer,
  subscriptions: subscriptionSlice.reducer,
  business: businessSlice.reducer,
  user: customerSlice.reducer,
  toastSlice: toastSlice.reducer,
  booking: bookingSlice.reducer,
  banner: bannerSlice.reducer,
  bookingTransactions : bookingTransactionsSlice.reducer,
  subscriptionTransactions : subscriptionTransactionsSlice.reducer,
  coupons: couponsSlice.reducer,
  countryCode : countryCodeReducer,
  bookingCoupon: bookingCouponsSlice.reducer
});