import React, { useEffect } from "react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  useFormikContext,
} from "formik";
import * as Yup from "yup";

import {
  Input,
  Select,
  DatePickerField,
} from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import { servicesData } from "../../demoData/servicesData";
import { packagesData } from "../../demoData/packagesData";

const SubTotal = () => {
  const { values, setFieldValue } = useFormikContext();
  const serviceValues = values.services;
  const packageValues = values.packages;
  const discount = values.discount ? values.discount.toFixed(2) : 0;

  useEffect(() => {
    const serviceTotal = serviceValues.reduce((serviceTotal, service) => {
      return serviceTotal + service.price;
    }, 0);

    const packageTotal = packageValues.reduce((packageTotal, singlePackage) => {
      return packageTotal + singlePackage.price;
    }, 0);

    const subTotal = +(serviceTotal + packageTotal).toFixed(2);
    const tax = +((subTotal - discount) * 0.18).toFixed(2);
    const total = +(subTotal + tax - discount).toFixed(2);

    console.log(total);

    setFieldValue("subTotal", subTotal);
    setFieldValue("tax", tax);
    setFieldValue("total", total);
  }, [serviceValues, packageValues, discount]);

  return (
    <div className="form-group row justify-content-end align-items-end">
      <div className="col-8">
        <h4 className="float-right">Sub-Total (₹)</h4>
      </div>
      <div className="col-4">
        <Field
          name="subTotal"
          type="number"
          min="1"
          component={Input}
          placeholder="0.00"
          disabled
        />
      </div>
    </div>
  );
};

export const BookingCreate = () => {
  const emptyServiceRow = {
    serviceId: servicesData[0].id,
    price: servicesData[0].price,
  };
  const emptyPackageRow = {
    packageId: packagesData[0].id,
    price: packagesData[0].price,
  };

  // Validation schema

  const BookingCreateSchema = Yup.object().shape({
    userId: Yup.string().required("This field is required"),
    branchId: Yup.string().required("This field is required"),
    date: Yup.date().required("This field is required"),
    startTime: Yup.string().required("This field is required"),
    duration: Yup.number().required("This field is required"),
    chair: Yup.string().required("This field is required"),
    staff: Yup.string().required("This field is required"),
    remarks: Yup.string(),
    subTotal: Yup.number().required("This field is required"),
    tax: Yup.number(),
    discount: Yup.number().required("This field is required"),
    total: Yup.number().required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add New Booking"}
      btnType={"submit"}
      formId={"createBookingForm"}
      headerBtnText={"Save Booking"}
    >
      <Formik
        initialValues={{
          userId: "Ariana D'souza - 9876789872",
          branchId: "1224",
          date: new Date(),
          startTime: "1130",
          duration: "30",
          chair: "0",
          remarks: "",
          staff: "1104",
          services: [emptyServiceRow],
          packages: [emptyPackageRow],
          // servicesTotal: 0,
          // packagesTotal: 0,
          subTotal: 0,
          tax: 0,
          discount: 0,
          // coupon: "",
          total: 0,
        }}
        validationSchema={BookingCreateSchema}
        onSubmit={(values, error) => {
          console.log(values);
          // saveCustomer(values);
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createBookingForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="userId"
                  component={Input}
                  label="Customer *"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="branchId" label="Select Branch *">
                  <option value="1223">World Hair Salon</option>
                  <option value="1224">World Amazing Salon</option>
                  <option value="1225">World Wax Salon</option>
                </Select>
              </div>

              <div className="col-lg-4">
                <div>
                  <label>Booking Date *</label>
                </div>
                <div className="w-100">
                  <DatePickerField
                    name="date"
                    className="form-control"
                  ></DatePickerField>
                </div>
              </div>
              <div className="col-lg-4">
                <Select name="startTime" label="Start Time *">
                  <option value="1130">11:30</option>
                  <option value="1430">14:30</option>
                  <option value="1500">15:00</option>
                  <option value="1530">15:30</option>
                  <option value="1730">17:30</option>
                  <option value="1930">19:30</option>
                  <option value="2000">20:00</option>
                </Select>
              </div>
            </div>
            {/* Contact Number */}
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="duration"
                  type="number"
                  min="30"
                  step="30"
                  component={Input}
                  placeholder="30"
                  label="Duration (Minutes) *"
                />
              </div>
              <div className="col-lg-4">
                <Select name="chair" label="Chair *">
                  <option value="0">Chair 1</option>
                  <option value="1">Chair 2</option>
                  <option value="2">Chair 3</option>
                  <option value="3">Chair 4</option>
                </Select>
              </div>
              <div className="col-lg-4">
                <Select name="staff" label="Staff *">
                  <option value="1101">Riya Ahuja</option>
                  <option value="1102">Ronak Mishra</option>
                  <option value="1104">Alivia Prasad</option>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Booking Remarks</label>
                <Field
                  name="remarks"
                  as="textarea"
                  rows="2"
                  className="form-control"
                />
              </div>
            </div>
            <h3 className="mt-10">Select Services *</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Service</label>
              </div>
              <div className="col-4">
                <label>Price (₹)</label>
              </div>
            </div>
            <FieldArray name="services">
              {({ push, remove }) => (
                <>
                  {values.services.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          name={`services.[${index}].serviceId`}
                          onChange={(e) => {
                            setFieldValue(
                              `services.[${index}].serviceId`,
                              e.target.value
                            );
                            setFieldValue(
                              `services.[${index}].price`,
                              servicesData[e.target.value].price
                            );
                          }}
                        >
                          {servicesData.map((service, index) => (
                            <option value={service.id} key={service.id}>
                              {service.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`services.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => index > 0 && remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <div className="form-group row justify-content-end align-items-end">
                    <div className="col-8">
                      <h4 className="float-right">Services Total (₹)</h4>
                    </div>
                    <div className="col-4">
                      <Field
                        name="servicesTotal"
                        type="number"
                        min="1"
                        component={Input}
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyServiceRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Service
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <h3 className="mt-10">Select Package</h3>
            <hr />
            <div className="form-group row mb-0">
              <div className="col-8">
                <label>Package</label>
              </div>
              <div className="col-4">
                <label>Price (₹)</label>
              </div>
            </div>
            <FieldArray name="packages">
              {({ push, remove }) => (
                <>
                  {values.packages.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-8">
                        <Select
                          name={`packages.[${index}].packageId`}
                          onChange={(e) => {
                            setFieldValue(
                              `packages.[${index}].packageId`,
                              e.target.value
                            );
                            setFieldValue(
                              `packages.[${index}].price`,
                              packagesData[e.target.value].price
                            );
                          }}
                        >
                          {packagesData.map((singlePackage, index) => (
                            <option
                              value={singlePackage.id}
                              key={singlePackage.id}
                            >
                              {singlePackage.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-4 d-flex">
                        <Field
                          name={`packages.[${index}].price`}
                          type="number"
                          component={Input}
                          disabled
                        />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => index > 0 && remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <div className="form-group row justify-content-end align-items-end">
                    <div className="col-8">
                      <h4 className="float-right">Packages Total (₹)</h4>
                    </div>
                    <div className="col-4">
                      <Field
                        name="packagesTotal"
                        type="number"
                        min="1"
                        component={Input}
                        placeholder="0.00"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyPackageRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Package
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <hr />
            <SubTotal />
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Discount (₹)</h4>
              </div>
              <div className="col-4">
                <Field
                  name="discount"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Tax (₹)</h4>
              </div>
              <div className="col-4">
                <Field
                  name="tax"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row justify-content-end align-items-end">
              <div className="col-8">
                <h4 className="float-right">Total (₹)</h4>
              </div>
              <div className="col-4">
                <Field
                  name="total"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="0.00"
                  disabled
                />
              </div>
            </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
