import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./subscriptionTransactionsApi";

const initialState = {
  subscriptionTransactions: [],
};

export const subscriptionTransactionsSlice = createSlice({
  name: "subscriptionTransactions",
  initialState,
  reducers: {
    updateList(state, action) {
      state.subscriptionTransactions = action.payload;
    },
    created(state, action) {
      state.subscriptionTransactions.push(action.payload);
    },
  },
});

export const loadSubscriptionTransactionsList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getSubscriptionTransactionsList()
      .then((res) => {
        dispatch(subscriptionTransactionsSlice.actions.updateList(res.data.data));
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };
};
