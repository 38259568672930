import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../src/_metronic/_partials/controls/Card";

export const CardBasic = (props) => {
  console.log(props);

  const headerTitle = props.title;
  const headerBtnText = props.headerBtnText;
  const headerBtnLink = props.headerBtnLink;
  const btnType = props.btnType;
  const formId = props.formId;

  return (
    <Card className="mt-4 mt-xl-0">
      {headerTitle && (
        <CardHeader title={headerTitle}>
          {headerBtnText && (
            <CardHeaderToolbar>
              {btnType === "button" && (
                <Link
                  to={headerBtnLink ?? ""}
                  className="btn btn-light-success"
                >
                  {headerBtnText}
                </Link>
              )}
              {btnType === "submit" && (
                <button
                  type={btnType}
                  className="btn btn-light-success"
                  form={formId}
                >
                  {headerBtnText}
                </button>
              )}
            </CardHeaderToolbar>
          )}
        </CardHeader>
      )}
      <CardBody>{props.children}</CardBody>
      {btnType === "submit" && (
        <CardFooter>
          <button
            type={btnType}
            className="btn btn-light-success float-right"
            form={formId}
          >
            {headerBtnText}
          </button>
        </CardFooter>
      )}
    </Card>
  );
};
