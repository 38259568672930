export const packagesData = [
  {
    id: 0,
    name: "Hair & Beard",
    price: 599,
    discount_price: 449,
    time: 60,
    status: true,
  },
  {
    id: 1,
    name: "Full Body Wax",
    price: 2499,
    discount_price: 1999,
    time: 120,
    status: false,
  },
  {
    id: 2,
    name: "Bridal Makeup",
    price: 5999,
    discount_price: 4999,
    time: 180,
    status: true,
  },
];