import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/admin/business";
const URL2 = process.env.REACT_APP_API_URL + "/admin";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;

const headers = {
  Authorization,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBusinessList = () => {
  return axios.get(`${URL}/list`, {
    headers: {
      Authorization,
    },
  });
};

export const updateBusiness = (businessUuid, status) => {
  const req = {
    businessUuid: businessUuid,
    isActive: !status
  }

  return axios.patch(`${URL}/update`, req, {
    headers,
  });
};

export const createBusiness = (data) => {
  const req = new FormData();

  for (var key in data) {
    req.append(key, data[key]);
  }

  console.log([...req]);

  return axios.post(`${URL2}/business/create`, data, {
    headers,
  });
};

export const businessResetPassword = (values) => {
  //console.log(values);
  return axios.patch(`${URL}/password/reset`, values);
};

export const businessPhoneChange = (values) => {
  //console.log(values);
  return axios.patch(`${URL}/phone/update`, values);
};

export const businessSubscriptionExtendAndUograde = (values) => {
  console.log(values);
  return axios.post(`${URL2}/subscription/modify`, values);
};

export const businessUserList = (values) => {
  console.log(values);
  return axios.post(`${URL2}/branch/list`, values);
};