import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./businessApi";

const initialState = {
  business: [],
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    created(state, action) {
      state.business.push(action.payload);
    },
    updateBusinessList(state, action) {
      state.business = action.payload;
    },
    updateBusinessStatus(state, action) {
      //console.log(action.payload);
      const businessIndex = state.business.findIndex(
        (bus) => bus.uuid === action.payload.businessUuid
      );
      state.business[businessIndex].isActive = !action.payload.status;
    },
    created(state, action) {
      state.business.push(action.payload);
    },
    updateBusinessListItem(state, action) {
      //console.log(action.payload);
      const businessIndex = state.business.findIndex(
        (business) => business.uuid === action.payload.uuid
      );
      //console.log(businessIndex);
      if (businessIndex > -1) {
        state.business.splice(businessIndex, 1);
      }
      state.business.push(action.payload);
      // console.log(action.payload);
    },
  },
});

export const getBusinessListData = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBusinessList()
      .then((response) => {
        console.log(response);
        dispatch(businessSlice.actions.updateBusinessList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const updateBusinessStatus = (businessUuid, status) => {
  return async (dispatch) => {
    return requestFromServer
      .updateBusiness(businessUuid, status)
      .then((response) => {
        //console.log(response.data);
        dispatch(businessSlice.actions.updateBusinessStatus({ businessUuid, status }));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const createBusiness = (data) => {
 // console.log(data);
  return async (dispatch) => {
    return requestFromServer
      .createBusiness(data)
      .then((response) => {
        dispatch(businessSlice.actions.created(response.data.data));
      })
      .catch((error) => {
        //console.log(error.response.data.message);
        throw new Error(error.response.data.message);
      });
  };
};

export const updateBusinessData = (data) => {
  //console.log(data);
  return async (dispatch) => {
    dispatch(businessSlice.actions.updateBusinessListItem(data));
  };
};
