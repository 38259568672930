import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";

import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { loadUserList } from "../../store/admin/customer/customerSlice";
import dayjs from "dayjs";

export const CustomerTable = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.user.user);
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadUserList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const linkFormatter = (cell, row) => {
    //console.log(row);
      return (
        <Link className="text-uppercase" to={`/customer/loyaltyPoints/${row.phone}`}>
          <span className="text-uppercase">{cell}</span>
        </Link>
      );
  };

  const linkFormatter2 = (cell, row) => {
    //console.log(row);
      return (
        <Link className="text-uppercase" to={`/customer/booking/${row.phone}`}>
          <span className="text-uppercase">{cell}</span>
        </Link>
      );
  };

  const DateFormatter = (cell, row) => {
    return `${dayjs(`${cell}`).format("DD-MM-YYYY")}`;
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index+1,
    },
    {
      dataField: "createdAt",
      text: "Joined",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "dob",
      text: "DOB",
    },
    {
      dataField: "BookingCount",
      text: "Bookings",
      formatter: linkFormatter2,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Wallet[0].balance",
      text: "Loyalty Points",
      formatter: linkFormatter,
    }
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={customers} columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
