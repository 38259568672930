import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import { DatePickerField } from "../../_metronic/_partials/controls";

import { sortCaret } from "../../_metronic/_helpers";

import {
  BookingStatusColumnFormatterForBooking,
  BookingActionColumn,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadBookingList } from "../../store/admin/bookings/bookingSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

export const CustomerBookingTable = (props) => {
  const { PHONE } = useParams();
  //console.log(PHONE)
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.booking.bookings);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  const [dataLoading, setDataLoading] = useState(true);
  let bookingStatus = [];
  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);
  //console.log(bookingData);
  const linkFormatter = (cell, row) => {
    return <span className="text-uppercase">{cell}</span>;
  };

  if (props.status == "cancelled") {
    bookingStatus = [0];
  } else if (props.status == "upcoming") {
    bookingStatus = [1, 2, 3];
  } else if (props.status == "completed") {
    bookingStatus = [4];
  }
  //console.log(bookingStatus)

  const RegisteredEvents = bookingData.filter((obj1) => {
    // console.log(obj1);
    return obj1.customer.phone === PHONE;
  });
  //console.log(RegisteredEvents)
  const date = new Date();
  date.setDate(date.getDate() - 365);
  const dateEnd = new Date();
  dateEnd.setDate(dateEnd.getDate() + 60);

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  useEffect(() => {
    dispatch(loadBookingList({ from: date, to: dateEnd })).then(() => {
      setDataLoading(false);
    });
  }, [props.status]);

  const TimeFormatter = (cell, row) => {
    return dayjs(cell).format("hh:mm A");
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const columns = [
    {
      dataField: "bookingId",
      text: "Booking Number",
      sort: true,
      sortCaret: sortCaret,
      formatter: linkFormatter,
    },
    {
      dataField: "customer.name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "customer.phone",
      text: "Customer Phone",
      sort: true,
    },
    {
      dataField: "branch[0].name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "date",
      text: "Booking Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "dateTime",
      text: "Start Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: TimeFormatter,
    },
    {
      dataField: "duration",
      text: "Duration (Minutes)",
    },
    {
      dataField: "total",
      text: `Booking Amount (${
        currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
      })`,
    },
    {
      dataField: "paidAmount",
      text: `Paid Amount (${
        currencySymbol[0] ? currencySymbol[0].currencySymbol : "₹"
      })`,
    },
    {
      dataField: "status",
      text: "Booking Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: BookingStatusColumnFormatterForBooking,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
      <Formik
        initialValues={{
          startDate: date,
          endDate: dateEnd,
        }}
        onSubmit={
          (values, errors) => {
            //console.log(values);
            dispatch(
              loadBookingList({ from: values.startDate, to: values.endDate })
            ).then(() => {
              setDataLoading(false);
            });
          }
          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue }) => {
          //console.log(values);
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <div
                className="form-group row"
                style={{ justifyContent: "center", alignItems: "end" }}
              >
                <div className="mx-3">
                  <label>From</label>
                </div>
                <DatePickerField
                  name="startDate"
                  className="form-control"
                  onChange={(val) => {
                    setFieldValue("startDate", val);
                  }}
                />

                <div className="mx-3">
                  <label>To</label>
                </div>
                <DatePickerField
                  name="endDate"
                  className="form-control"
                  onChange={(val) => {
                    setFieldValue("endDate", val);
                  }}
                />

                <button
                  type="submit"
                  class="btn btn-light-success font-weight-bold"
                  style={{ marginLeft: " 20px" }}
                >
                  Filter Data
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <ToolkitProvider
        search
        keyField="bookingId"
        data={RegisteredEvents}
        columns={columns}
      >
        {(props) => (
          <>
            <div
              className="form-group row "
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <h4 className="mb-0 mr-4 float-right">Search</h4>
              <div className="flex">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
                {/* <div className="d-flex justify-content-center">
                  <div className="mr-5">
                    <input
                      name="email"
                      type="text"
                      variant="outlined"
                      value={PHONE}
                      disabled
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #dad1d1",
                        background: "#f7f7f7",
                        height: "35px",
                        width: "180px",
                        padding: "10px",
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              wrapperClasses="table-responsive"
              bordered={false}
              bootstrap4
              loading={true}
              pagination={paginationFactory()}
              filter={filterFactory()}
              noDataIndication={
                <div className={"d-flex justify-content-center py-10"}>
                  <h3>
                    {dataLoading ? "Loading Data..." : "No records found"}
                  </h3>
                </div>
              }
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
