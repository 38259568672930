import React from "react";

import { SubscriptionTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function SubscriptionsPage() {
  return (
    <CardBasic
      title={"Subscription"}
      btnType={"button"}
      headerBtnText={"New Subscription"}
      headerBtnLink={"/subscription/create"}
    >
      <SubscriptionTable />
    </CardBasic>
  );
}
