/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.authPersist);
  return (
    <div className="topbar-item ml-2">
      <div
        className={
          "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        }
      >
        <Link to="/profile">
        <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
          Hi,
        </span>{" "}
        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
          {user?.name}
        </span>
        <span className="symbol symbol-35 symbol-light-success">
          <span className="symbol-label font-size-h5 font-weight-bold">
            {user.name ? user.name[0] : null}
          </span>
        </span>
        </Link>
      </div>
    </div>
  );
}
