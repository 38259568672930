import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls/Card";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../_metronic/_partials/controls";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { sendFailure, sendSuccess } from "../../../store/toast/toastSlice";
import { createCustomerLoyaltyPoints } from "../../../store/admin/loyaltyPoints/customerLoyaltyPointsApi";

export const LoyaltyPointCustomerCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({});
  const [open, setOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    remarks: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    amount: Yup.number().required("This field is required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Confirm = () => {
    //console.log(formValue);
    createCustomerLoyaltyPoints(formValue)
      .then((response) => {
        //console.log(response);
        history.replace(`/customer/loyaltyPoints/${formValue.phone}`);
          // dispatch(sendSuccess(
          //   "The Banner has been deleted successfully"
          // ));
        setOpen(false);
      })
      .catch((err) => {
        alert('Error! Customer not found!');
        dispatch(sendFailure(err.errorMessage));
        //console.log('Error! Business not found!');
        setOpen(false);
      });
  };

  return (
    <>
      <Card className="mt-4 mt-xl-0">
        <CardHeader title="Add Customer Loyalty Point"></CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              phone: "",
              type: "credit",
              amount: 0,
              remarks: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              //console.log(values);
              setFormValue(values);
              setOpen(true);
              // dispatch(createCoupon(values))
              //   .then(() => {
              //     //console.log("here");
              //     history.replace("/coupons");
              //   })
              //   .catch((err) => {
              //     actions.setStatus("Error! Duplicate coupon.");
              //     console.log(err.message);
              //   });
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <Form className="form form-label-right" id="createForm">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="phone"
                      type="text"
                      component={Input}
                      placeholder="Phone Number"
                      label="Customer Phone Number *"
                    />
                  </div>
                  <div className="col-lg-6">
                    <Select name="type" label="Type *">
                      <option value="credit">Credit</option>
                      <option value="debit">Debit</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="amount"
                      type="number"
                      min="0"
                      component={Input}
                      placeholder="Amount"
                      label="Amount *"
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="remarks"
                      type="text"
                      component={Input}
                      label="Remarks *"
                    />
                  </div>
                </div>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </CardBody>
        <CardFooter>
          <button
            type="submit"
            className="btn btn-light-success float-right"
            form="createForm"
          >
            Save Loyalty Point
          </button>
        </CardFooter>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Are you sure you want to add Loyalty Points to this Customer?"}
            <p>(This transaction cannot be reversed)</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="btn btn-danger">
            No, Go back.
          </button>
          <button onClick={Confirm} className="btn btn-light-success">
            Yes, Continue.
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
