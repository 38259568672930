import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";

import {
  StatusColumnFormatter,
} from "../../_metronic/_helpers/tableColumnFormatter";
import { Link } from "react-router-dom";

import paginationFactory from "react-bootstrap-table2-paginator";

import { packagesData } from "../../demoData/packagesData";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  loadSubscriptionList,
} from "../../store/admin/subscription/subscriptionSlice";

export const TransactionTable = () => {
  const [formStatus, setFormStatus] = useState("");

  const data = packagesData;
  const state2 = useSelector((state) => state.subscriptions.subscriptions);
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    console.log(state2);
    dispatch(loadSubscriptionList()).then((res) => {
      console.log(state2);
      setDataLoading(false);
    });
  }, []);
  var id = 1;
  const LinkFormatter = (cell, row) => {
    return <Link to={`subscription/${row.uuid}`}>{cell}</Link>;
  };
  const columns = [
    {
      dataField: id,
      text: "#",
      formatter: (cell, row) => id++,
    },
    {
      dataField: "name",
      text: "Subscription Name",
      sort: true,
      sortCaret: sortCaret,
      formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "annual_cost",
      text: "Annual cost",
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      search
      keyField="id"
      data={state2}
      columns={columns}
      autoValue
    >
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            insertRow
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
