export const servicesData = [
  {
    id: 0,
    name: "Super Hair Cut",
    price: 299,
    discount_price: 199,
    time: 30,
    status: true,
  },
  {
    id: 1,
    name: "Super Hand Wax",
    price: 599,
    discount_price: 499,
    time: 30,
    status: true,
  },
  {
    id: 2,
    name: "Super Full Legs Wax",
    price: 799,
    discount_price: 699,
    time: 45,
    status: true,
  },
];