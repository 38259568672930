import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as requestFromServer from "./authApi";

const initialState = {
  user: {},
  authenticated: false,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.authenticated = true;
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    },
    logout(state) {
      state.authenticated = false;
      state.token = null;
      state.user = {};
      console.log("HERE--->>>> " + state.token);
    },
  },
});

export const loginAction = (email, password) => {
  return async (dispatch) => {
    return requestFromServer
      .login(email, password)
      .then((response) => {
        dispatch(authSlice.actions.login(response.data));
        return response.data;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const preducer = persistReducer(
  { storage, key: "auth-cred" },
  authSlice.reducer
);

export const authActions = authSlice.actions;

export const authPersist = preducer;
