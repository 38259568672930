import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SegmentsTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function SegmentsPage() {
  const { role, user } = useSelector((state) => state.authPersist);
  //console.log(user);
  return (
    <CardBasic
      title={"Business Segment List"}
      btnType={user.countryCode === 91 ? "button" : ""}
      headerBtnText={"New Business Segment"}
      headerBtnLink={"/segment/create"}
    >
      <SegmentsTable />
    </CardBasic>
  );
}
