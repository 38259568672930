import React from "react";

import { LoyaltyPointsTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";

export function LoyaltyPointsCustomerPages() {
  return (
    <CardBasic
      title={"Customer Loyalty Points"}
      btnType={"button"}
      headerBtnText={"Add Customer Loyalty Point"}
      headerBtnLink={"/customer/loyaltyPoint/create"}
    >
      <LoyaltyPointsTable />
    </CardBasic>
  );
}
