import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/admin";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getPlatformReport = () => {
  return axios.get(`${URL}/report/platform`);
};

export const getTopBranchReport = () => {
  return axios.get(`${URL}/report/branch`);
};

export const getSubscriptionReport = () => {
  return axios.get(`${URL}/report/subscription`);
};

export const getBusinessSalesReport = (date) => {
  console.log(date);
  const req = {
    "from": date.to,
    "to": date.from
  };
  return axios.post(`${URL}/business/salesreport`, req);
};

export const getBusinessReportAdmin = () => {
  return axios.get(`${URL}/business/report`);
};

export const getClientReport = () => {
  return axios.get(`${URL}/user/report/client`);
};