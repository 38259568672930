import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import dayjs from "dayjs";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Switch from "@material-ui/core/Switch";

import { BusinessActionColumn } from '../../_metronic/_helpers/tableColumnFormatter';
import {
  getBusinessListData,
  updateBusinessStatus,
} from "../../store/admin/business/businessSlice";

export const BusinessUserTable = () => {
  const dispatch = useDispatch();
  let Business = useSelector((state) => state.business.business);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getBusinessListData()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const changeUserStatus = (businessUuid, status) => (event) => {
    //console.log({ businessUuid, status });
    dispatch(updateBusinessStatus(businessUuid, status)).then(() => {
      setDataLoading(false);
    });
  };

  const linkFormatter = (cell, row) => {
   // console.log(row);
      return (
        <Link className="text-uppercase" to={`/business/loyaltyPoints/${row.email}`}>
          <span className="text-uppercase">{cell}</span>
        </Link>
      );
  };

  const linkFormatter2 = (cell, row) => {
    //console.log(row);
       return (
         <Link className="text-uppercase" to={`/business/details/${row.uuid}`}>
           <span className="text-uppercase">{cell}</span>
         </Link>
       );
   };

  const statusFormatter = (cell, row) => {
    return (
      <Switch
        checked={row.isActive}
        color="primary"
        onChange={changeUserStatus(row.uuid, cell)}
      />
    );
  };

  const DateFormatter = (cell, row) => {
    return `${dayjs(`${cell}`).format("DD-MM-YYYY")}`;
  };
  const ActionDisplay = (cell, row) => {
      return BusinessActionColumn(cell, row);
  };
  const columns = [
    {
      dataField: "business_name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "subscription.name",
      text: "Plan",
      sort: true,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "BranchCount",
      text: "BR",
      formatter: linkFormatter2,
    },
    {
      dataField: "StaffCount",
      text: "ST",
    },
    {
      dataField: "ChairCount",
      text: "CH",
    },
    {
      dataField: "BookingCount",
      text: "BK",
    },
    {
      dataField: "ServiceCount",
      text: "SR",
    },
    {
      dataField: "PackageCount",
      text: "PK",
    },
    {
      dataField: "subscription.date",
      text: "Joined",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "subscription.expiry",
      text: "Renewal",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    {
      dataField: "wallet[0].balance",
      text: "Loyalty Points",
      formatter: linkFormatter,
    },
    {
      dataField: "isActive",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      formatter: statusFormatter,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: ActionDisplay,
    }
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={Business} columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom overflow-hidden react-bootstrap-table"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
