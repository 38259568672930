import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/admin";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getSubscriptionTransactionsList = async () => {
  return await axios.get(`${URL}/subscription/transaction/report`);
};
