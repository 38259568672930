import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import * as Yup from "yup";
import { sortCaret } from "../../../_metronic/_helpers";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";

import paginationFactory from "react-bootstrap-table2-paginator";

import { useDispatch } from "react-redux";
import { loadCustomerLoyaltyPointsList } from "../../../store/admin/loyaltyPoints/customerLoyaltyPointsApiSlice";
import { getCustomerLoyaltyPointsList } from "../../../store/admin/loyaltyPoints/customerLoyaltyPointsApi";

export const LoyaltyPointsTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [wallet, setWallet] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const { phone } = useParams();
  // console.log(phone);
  // console.log(data);
  //console.log(data);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
  });

  useEffect(() => {
    if (phone !== undefined) {
      getCustomerLoyaltyPointsList({ phone: phone }).then((res) => {
        //console.log(res);
        if (res.data.data.Loyalty_Points.length > 0) {
          setData(res.data.data.Loyalty_Points);
          setWallet(res.data.data.Wallet_Details);
        } else {
          setData(res);
          setErrorLoading(true);
        }
        setDataLoading(false);
      });
    }
  }, [phone]);

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const LinkFormatter = (cell, row) => {
    return <span>{cell.charAt(0).toUpperCase() + cell.slice(1)}</span>;
  };

  const TypeFormatter = (cell, row) => {
    if (row.type === "debit") {
      return (
        <p>
          <span
            style={{
              color: "red",
              fontWeight: "700",
              fontSize: "14px",
              marginRight: "5px",
            }}
          >
            -
          </span>
          {cell}
        </p>
      );
    }
    return (
      <p>
        <span
          style={{ fontWeight: "700", fontSize: "14px", marginRight: "5px" }}
        >
          +
        </span>
        {cell}
      </p>
    );
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index + 1,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: TypeFormatter,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   formatter: LinkFormatter,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      formatter: LinkFormatter,
      sortCaret: sortCaret,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider
      //search
      keyField="uuid"
      data={data}
      columns={columns}
      autoValue
    >
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            {/* <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              /> */}
              <Formik
                initialValues={{
                  phone: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, errors) => {
                  //console.log(values);
                  dispatch(loadCustomerLoyaltyPointsList(values))
                    .then((response) => {
                      //console.log(response);
                      if (response.Loyalty_Points.length > 0) {
                        setData(response.Loyalty_Points);
                        setWallet(response.Wallet_Details);
                      } else {
                        setData(response);
                        setErrorLoading(true);
                      }
                      setDataLoading(false);
                      //handleClose();
                    })
                    .catch((err) => {
                      //console.log(err.message);
                      setErrorLoading(true);
                    });
                }}
              >
                {({ values, errors, touched, handleChange }) => {
                  // console.log(values);
                  return (
                    <Form className="container">
                      <div className="d-flex justify-content-center">
                        <div className="mr-5">
                          <TextField
                            name="phone"
                            type="text"
                            variant="outlined"
                            value={values.phone}
                            onChange={handleChange}
                            error={errors.phone && touched.phone}
                            helperText={touched.phone && errors.phone}
                            //className={classes.textField}
                            style={{
                              borderRadius: " 10px 0px 0px 10px",
                              height: "56px",
                              width: "220px",
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className="btn bg-primary font-weight-bold text-white"
                            type="submit"
                            // onClick={() => {}}
                            style={{
                              borderRadius: "10px",
                              width: "100px",
                              height: "50px",
                              padding: "5px",
                              lineHeight: "16px",
                              fontSize: "12px",
                            }}
                          >
                            Show Loyalty Points
                          </button>
                        </div>
                      </div>
                      {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                    </Form>
                  );
                }}
              </Formik>
            {/* </div> */}
            <div className="container mt-10">
              <div class="d-flex justify-content-center">
                <h4 className="mb-5 float-right">
                  Loyalty Points{" "}
                  <span
                    style={{
                      padding: "8px",
                      backgroundColor: "#F3F6F9",
                      borderRadius: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    {wallet.balance ? wallet.balance : '0'}
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            insertRow
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>
                  {!dataLoading && !errorLoading
                    ? "Enter Phone Number to view Customer Loyalty Points"
                    : dataLoading
                    ? "Loading Data..."
                    : "No records found"}
                </h3>

                {/* <h3>
                  {dataLoading || (!dataLoading && errorLoading)
                    ? "Loading Data..."
                    : "No records found"}
                </h3> */}
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
