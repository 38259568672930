import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./countryCodeApi";

const initialState = {
  countryCode: [],
  calenderType: []
};

const countryCodeSlice = createSlice({
  name: "countryCode",
  initialState,
  reducers: {
    updateCountryCodeList(state, action) {
      state.countryCode = action.payload;
    },
    updateCalenderType(state, action) {
      state.calenderType = action.payload;
    }
  },
});

export const getCountryCodeList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCountryCodeList()
      .then((response) => {
        //console.log(response.data);
        dispatch(countryCodeSlice.actions.updateCountryCodeList(response.data.data.CountryCodes));
        dispatch(countryCodeSlice.actions.updateCalenderType(response.data.data.CalenderType));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const countryCodeActions = countryCodeSlice.actions;

export default countryCodeSlice.reducer;