import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { Formik, Form } from "formik";
import { DatePickerField } from "../../_metronic/_partials/controls";

import { sortCaret } from "../../_metronic/_helpers";

import {
  BookingStatusColumnFormatter,
  BookingActionColumn,
} from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { loadBookingTransactionsList } from "../../store/admin/bookingTransactions/bookingTransactionsSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

export const BookingTransactionsTable = (props) => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.booking.bookings);
  const bookingTransactionsData = useSelector((state) => state.bookingTransactions.bookingTransactions);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const [dataLoading, setDataLoading] = useState(true);

  const linkFormatter = (cell, row) => {
    return <span className="text-uppercase">{cell}</span>;
  };

  useEffect(() => {
    dispatch(getCountryCodeList());
}, []);

  const date = new Date();
  date.setDate(date.getDate() - 15);
  const dateEnd = new Date();
  dateEnd.setDate(dateEnd.getDate() + 15);
  
  useEffect(() => {
    dispatch(loadBookingTransactionsList({ from: date, to: dateEnd })).then(() => {
      setDataLoading(false);
    });
  }, [props.status]);

  const TimeFormatter = (cell, row) => {
    return dayjs(cell).format("hh:mm A");
  };

  const DateFormatter = (cell, row) => {
    return dayjs(cell).format("DD-MM-YYYY");
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index+1,
    },
    {
      dataField: "Booking[0].bookingId",
      text: "Booking Number",
      sort: true,
      sortCaret: sortCaret,
      formatter: linkFormatter,
    },
    {
      dataField: "Booking[0].customer.name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Booking[0].customer.phone",
      text: "Customer Phone",
      sort: true,
    },
    {
      dataField: "Business[0].business_name",
      text: "Branch Name",
      sort: true,
      sortCaret: sortCaret,
      // filter: textFilter(),
    },
    {
      dataField: "date",
      text: "Booking Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: DateFormatter,
    },
    // {
    //   dataField: "dateTime",
    //   text: "Start Time",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: TimeFormatter,
    // },
    {
      dataField: "paymentMode",
      text: "Payment Mode",
    },
    {
      dataField: "Booking[0].total",
      text: `Booking Amount (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹' })`,
    },
    {
      dataField: "amount",
      text: `Paid Amount (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹' })`,
    },
    {
      dataField: "Booking[0].status",
      text: "Booking Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: BookingStatusColumnFormatter,
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
    <Formik
        initialValues={{
          startDate: date,
          endDate: dateEnd
        }}
        onSubmit={
          (values, errors) => {
            console.log(values);
              dispatch(loadBookingTransactionsList({ from: values.startDate, to: values.endDate })).then(() => {
                setDataLoading(false);
              });
          }
          // saveCustomer(values);
        }
      >
        {({ values, errors, setFieldValue }) => {
          //console.log(values);
          return (
            <Form className="form form-label-right" id="createBookingForm">
              <div className="form-group row" style={{ justifyContent: 'center', alignItems: "end", }}>

                <div className="mx-3">
                  <label>From</label>
                </div>
                <DatePickerField
                  name="startDate"
                  className="form-control"
                  onChange={(val) => {
                    setFieldValue("startDate", val);
                  }}
                />


                <div className="mx-3">
                  <label>To</label>
                </div>
                <DatePickerField
                  name="endDate"
                  className="form-control"
                  onChange={(val) => {
                    setFieldValue("endDate", val);
                  }}
                />

                <button type="submit" class="btn btn-light-success font-weight-bold" style={{ marginLeft: ' 20px' }}>
                  Filter Data
                </button>
              </div>
            </Form>)
        }}

      </Formik>
   
    <ToolkitProvider
      search
      keyField="bookingId"
      data={bookingTransactionsData}
      columns={columns}
    >
      {(props) => (
        <>
          <div className="form-group row " style={{ justifyContent: 'center', alignItems: 'center' }}>
              <h4 className="mb-0 mr-4 float-right">Search</h4>
              <div className="flex">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton
                  {...props.searchProps}
                  className="ml-4 btn btn-secondary-light"
                />
              </div>
            </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
    </>
  );
};
