import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";

export function BusinessProfile(props) {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const business = props.business;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimum 5 characters")
      .required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
  });
  return (
    <CardBasic
      title={"ADMIN PROFILE"}
      // btnType={"button"}
      // headerBtnText={"Update"}
      // headerBtnLink={"/profile/update"}
    >
      <Formik
        initialValues={{
          name: business.name,
          phone: business.phone,
          email: business.email,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          console.log(values);
          // saveCustomer(values);
        }}
      >
        <Form className="form form-label-right" id="createBranchForm">
          <div className="form-group row">
            <div className="col-12">
              <Field
                name="name"
                component={Input}
                placeholder="Name"
                label="Admin Name *"
              />
            </div>
          </div>
          {/* Contact Number */}
          <div className="form-group row">
            <div className="col-lg-6">
              <Field
                name="phone"
                component={Input}
                placeholder="9899898893"
                label="Phone *"
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="email"
                type="email"
                component={Input}
                placeholder="example@domain.com"
                label="Email Address *"
              />
            </div>
          </div>
          {/* <div className="form-group row">
            <div className="col-lg-6">
              <Field
                name="password"
                component={Input}
                type="password"
                label="Password"
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="confirmPassword"
                type="password"
                component={Input}
                label="Confirm Password"
              />
            </div>
          </div> */}
        </Form>
      </Formik>
    </CardBasic>
  );
}
