import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/admin";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// export const getBookingTransactionsList = async () => {
//   return await axios.get(`${URL}/booking/listByDate`);
// };

export const getBookingTransactionsList = (data) => {
  const req = {
    //bookingStatus: data.bookingStatus,
    from: data.from,
    to: data.to,
  };
  console.log(req);
  return axios.post(`${URL}/booking/transactions/listByDate`,  req, {
    headers,
  });
};
