import React from "react";
import { CardBasic } from "../../components/CardBasic";
import { RoleCreationTable } from "./tabel";

export function UsersPage() {
  return (
    <CardBasic
      title={"User List"}
      btnType={"button"}
      headerBtnText={"Add User"}
      headerBtnLink={"/user/create"}
    >
   <RoleCreationTable/>
    </CardBasic>
  );
}
