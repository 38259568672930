import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { Input, Select } from "../../_metronic/_partials/controls";

import { CardBasic } from "../../components/CardBasic";
import {
  getSegment,
  updateSegment,
} from "../../store/admin/segment/segmentApi";

export const SegmentView = () => {
  const [formStatus, setFormStatus] = useState({});
  const [segmentData, setSegmentData] = useState({});

  const history = useHistory();
  const { segmentUuid } = useParams();

  const req = {
    uuid: segmentUuid,
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    isActive: Yup.boolean().required("This field is required"),
  });

  useEffect(() => {
    getSegment(req)
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        setSegmentData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <CardBasic
      title={"Edit Segment"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Save Segment"}
    >
      <Formik
        initialValues={{
          name: segmentData.name ?? "",
          image: "",
          isActive: segmentData.isActive ?? false,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);

          updateSegment(values, segmentUuid)
            .then((response) => {
              console.log(response.data);
              history.replace("/segments");
            })
            .catch((err) => {
              actions.setStatus("Error! Duplicate Segment Name.");
              console.log(err.message);
            });
          setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="updateForm">
            {formProps.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formProps.status}
                </div>
              </div>
            )}
            {segmentData.image && (
              <div className="form-group row col">
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}/${segmentData.image}`}
                  width="300px"
                ></img>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Segment Name"
                  label="Segment Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <label>Segment Image (300 X 300 px)</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) =>
                    formProps.setFieldValue("image", e.target.files[0])
                  }
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="isActive" label="Status *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
