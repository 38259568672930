import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, Input } from "../../_metronic/_partials/controls";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import ClearIcon from "@material-ui/icons/Clear";
import { loadSubscriptionList } from "../../store/admin/subscription/subscriptionSlice";
import { businessSubscriptionExtendAndUograde } from "../../store/admin/business/businessApi";
import { updateBusinessData } from "../../store/admin/business/businessSlice";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

export default function ExtendDurationDialog({ content, svg, businessDetails }) {
  const data = useSelector((state) => state.subscriptions.subscriptions);
  console.log(data);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
    if (data.length === 0) {
      dispatch(loadSubscriptionList());
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const NewPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Please Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password.")
  });

  const submitHandler = (values) => {
    console.log(values);
    const newValue = { ...values, duration: +values.duration };
    console.log(newValue);
    dispatch(businessSubscriptionExtendAndUograde(newValue)
      .then((response) => {
        console.log(response.data.data);
        dispatch(updateBusinessData(response.data.data));
        dispatch(sendSuccess("Subscription update successfully"))
        handleClose();
      })
      .catch((err) => {
        dispatch(sendFailure(err.message));
        console.log(err.message);
      }));
  };


  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-title"
          className="d-flex justify-content-end align-items-center bg-primary text-white mb-5 w-100 pt-4 pb-4"
        >
          <div
            className="d-flex justify-content-between flex-row align-items-center pr-4 pl-5"
            style={{ width: "70%" }}
          >
            <h2>{content}</h2>
            <ClearIcon
              className="display-4"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <Formik
            initialValues={{
              duration: 0,
              businessUuid: businessDetails.status.uuid,
              subscriptionUuid: businessDetails.status.subscription.uuid
            }}
            //validationSchema={collectPaymentSchema}
            onSubmit={(values, errors) => {
              submitHandler(values);
            }}
          >
            {({ values, errors, touched }) => {
              console.log(values);
              return (
                <Form className="form form-label-right">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    {/* <div className="d-flex w-100 flex-row align-items-center justify-content-between">
                      <h4 style={{ margin: '0' }}>Package Name</h4>
                      <div className="w-25">
                        <Field name="amount" type="number" component={Input} />
                        <p className="font-weight-bold" style={{ fontSize: '16px', color: '#007669' }}>{businessDetails.status.subscription.name}</p>
                      </div>
                    </div> */}
                    <div className="d-flex w-100 flex-row mb-5 align-items-center justify-content-between">
                      <h4 style={{ margin: '0' }}>Package Name</h4>
                      <div className="w-25">
                        <Select name="subscriptionUuid">
                          {/* {data.map(el => {
                            console.log(el);
                            <option value="oneMonth">One Month</option>
                          })} */}
                          {data.map((el, index) => (
                            <option value={el.uuid} key={index}>
                              {el.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {/* {paymentMode === '' || paymentMode === 'Cash' ? null
                        : <div className="d-flex w-100 flex-row align-items-center justify-content-between mt-5">
                          <h4 style={{ margin: '0' }}>Payment Note</h4>
                          <div className="w-50">
                            <Field name="paymentNote" type="text" component={Input} />
                          </div>
                        </div>} */}
                    <div className="d-flex w-100 flex-row align-items-center justify-content-between">
                      <h4 style={{ margin: '0' }}>Duration</h4>
                      <div className="w-25">
                        <Select name="duration">
                          <option value={1}>One Month</option>
                          <option value={12}>One Year</option>
                          <option value={0}>No Extension</option>
                        </Select>
                      </div>
                    </div>
                    <button
                      className="btn bg-primary mb-5 mt-4 font-weight-bold h6 text-white"
                      type="submit"
                    >
                      Save & Close
                    </button>
                  </div>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
