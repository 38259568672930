import axios from "axios";
const URL = process.env.REACT_APP_API_URL + "/admin";
const token = process.env.REACT_APP_ADMIN_TOKEN;
const Authorization = "Bearer " + token;
const headers = {
  Authorization,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCustomerLoyaltyPointsList = async (data) => {
  //console.log(data);
  return await axios.post(`${URL}/customer/loyaltyPoints/list`, data, {
    headers,
  });
};

export const getCoupon = async (req) => {
  //console.log(req);
  return await axios.post(`${URL}/coupon/view`, req);
};

export const createCustomerLoyaltyPoints = (data) => {
 // console.log(data);
  return axios.post(`${URL}/customer/loyaltyPoints/create`, data, {
    headers,
  });
};

export const updateCoupone = (data, uuid) => {
  data.uuid = uuid;
  // console.log(data);
  return axios.patch(`${URL}/coupon/update`, data, {
    headers,
  });
};

