import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./bannerApi";

const initialState = {
  banners: []
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    updateList(state, action) {
      state.banners = action.payload;
    },
    updateBannerList(state, action) {
      // state.branch.image.push(action.payload);
      // const key = state.branch.image;
      // console.log(key);
      const BannerIndex = state.banners.findIndex(
        (el) => el.uuid === action.payload
      );
      console.log(BannerIndex);
      if (BannerIndex > -1) {
        state.banners.splice(BannerIndex, 1);
      }
    },
    created(state, action) {
      state.banners.push(action.payload);
    },
    edited(state, action) {
      const BannerIndex = state.banners.findIndex(
        (el) => el.uuid === action.payload
      );
      console.log(BannerIndex);
      if (BannerIndex > -1) {
        state.banners[BannerIndex] = action.payload;
      }
    },
  },
});

export const loadBannerList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getBannerList()
      .then((response) => {
        console.log(response.data.data);
        dispatch(bannerSlice.actions.updateList(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const uploadBannerImage = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .uploadBanner(data)
      .then((response) => {
       // console.log(response.data);
        dispatch(bannerSlice.actions.created(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const editBannerImage = (data) => {
  return async (dispatch) => {
    return requestFromServer
      .editBanner(data)
      .then((response) => {
       // console.log(response.data);
        dispatch(bannerSlice.actions.edited(response.data.data));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};

export const updateImageDeleteData = (uuid) => {
  console.log(uuid);
  return async (dispatch) => {
    dispatch(bannerSlice.actions.updateBannerList(uuid));
  };
};
