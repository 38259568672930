import React from "react";

import { CardBasic } from "../../components/CardBasic";
import { CustomerTable } from "./table";

export function CustomerPage() {
  return (
    <CardBasic
      title={"List of Customers"}
      btnType={"button"}
    >
      <CustomerTable />
    </CardBasic>
  );
}
