import React from "react";

import { LoyaltyPointsTable } from "./table";
import { CardBasic } from "../../../components/CardBasic";

export function LoyaltyPointsBusinessPages() {
  return (
    <CardBasic
      title={"Business Loyalty Points"}
      btnType={"button"}
      headerBtnText={"Add Business Loyalty Point"}
      headerBtnLink={"/business/loyaltyPoint/create"}
    >
      <LoyaltyPointsTable />
    </CardBasic>
  );
}
