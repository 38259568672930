import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";

import {
  getSubscription,
  updateSubscription,
} from "../../store/admin/subscription/subscriptionApi";

export const SubscriptionView = () => {
  const [subscriptionData, setSubscriptionData] = useState({});
  const history = useHistory();
  const emptyRow = "";
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const { uuid } = useParams();
  const req = {
    uuid: uuid,
  };

  useEffect(() => {
    getSubscription(req)
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        setSubscriptionData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    branch_credit: Yup.number()
      .min(1)
      .required("This field is required"),
    chair_credit: Yup.number()
      .min(1)
      .required("This field is required"),
    staff_credit: Yup.number()
      .min(1)
      .required("This field is required"),
    notification_credit: Yup.number()
      .min(0)
      .required("This Field is required"),
    isActive: Yup.string().required("This field is required"),
    benefits: Yup.array(Yup.string().required()).min(
      1,
      "Add atleast 1 benefit"
    ),
    annual_cost: Yup.number()
      .min(1)
      .positive("Price must be greater than 0")
      .required("This field is required")
      .typeError("Annual cost must be an number"),
  });

  return (
    <CardBasic
      title={"Edit Subscription"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Edit Subscription"}
    >
      <Formik
        initialValues={{
          name: subscriptionData.name ?? "",
          annual_cost: subscriptionData.annual_cost ?? "",
          //halfyear_cost: subscriptionData.halfyear_cost ?? "",
          month_cost: subscriptionData.month_cost ?? "",
          branch_credit: subscriptionData.branch_credit ?? "",
          chair_credit: subscriptionData.chair_credit ?? "",
          staff_credit: subscriptionData.staff_credit ?? "",
          notification_credit: subscriptionData.notification_credit ?? "",
          benefits: subscriptionData.benefits ?? [emptyRow],
          isActive: subscriptionData.isActive ?? false,
        }}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          updateSubscription(values, uuid)
            .then(() => {
              history.replace("/subscriptions");
            })
            .catch((err) => {
              actions.setStatus(err.message);
              console.log(err.message);
            });
          actions.setFormStatus("submitted");
        }}
      >
        {({ values, errors }) => (
          <Form className="form form-label-right" id="updateForm">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Subscription Name"
                  label="Subscritption Name *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="annual_cost"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="Enter Cost"
                  label={`Annual cost (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})*`}
                />
              </div>

              {/* <div className="col-lg-4">
                <Field
                  name="halfyear_cost"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="Enter Cost"
                  label={`Half yearly cost (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`}
                />
              </div> */}
              <div className="col-lg-6">
                <Field
                  name="month_cost"
                  type="number"
                  min="0"
                  component={Input}
                  placeholder="Enter Cost"
                  label={`Monthly cost (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})*`}
                />
              </div>
              {/* <div className="col-lg-4">
              <Field
                name="discount_price"
                type="number"
                min="0"
                component={Input}
                placeholder="0.00"
                label="Discounted Price (₹)"
              />
            </div> */}
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="branch_credit"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="Branches Allowed"
                  label="Branches Allowed *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="chair_credit"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="Chairs Allowed"
                  label="Chairs Allowed *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="staff_credit"
                  type="number"
                  min="1"
                  component={Input}
                  label="Staff Allowed *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="notification_credit"
                  type="number"
                  min="0"
                  component={Input}
                  label="Notifications Allowed *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12  ">
                <Select name="isActive" label=" Active *">
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Select>
              </div>
            </div>
            <label>Benefits *</label>
            <FieldArray name="benefits">
              {({ push, remove }) => (
                <>
                  {values.benefits.map((_, index) => (
                    <div className="form-group row" key={index}>
                      <div className="col-12 d-flex">
                        <Field name={`benefits.[${index}]`} component={Input} />
                        <span>
                          <a
                            className="btn font-weight-bold btn-danger btn-icon ml-2"
                            onClick={() => index > 0 && remove(index)}
                          >
                            <i className="flaticon2-cross icon-1x"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className="form-group row">
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn font-weight-bold btn-primary"
                        onClick={() => push(emptyRow)}
                      >
                        <i className="flaticon2-add-1 icon-1x"></i>Add Benefits
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
