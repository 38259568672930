import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  success: false,
  failure: false,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    sendSuccess(state, action) {
      state.success = true;
    },
    resendFailure(state, action) {
      state.failure = true;
    },
  },
});

export const sendSuccess = (msg) => {
  console.log(msg);
  return async (dispatch) => {
    return toast.success(msg);
  };
};

export const sendFailure = (msg) => {
  console.log(msg);
  return async (dispatch) => {
    return toast.error(msg);
  };
};
