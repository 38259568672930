import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";

export const UsersView = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(4, "Enter 4 digit phone number")
      .max(20, "Enter 20 digit phone number")
      .required("This field is required"),
    isActive: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    role: Yup.string().required("Type is required"),
  });

  const data = {
    id: "1",
    name: "demo",
    role_assign: "Branch Manager",
    isActive: "true",
    phone: "9898989898",
    email: "demo@gmail.com",
  };

  return (
    <CardBasic
      title={"Edit Role"}
      btnType={"submit"}
      formId={"updateForm"}
      headerBtnText={"Save User"}
    >
      <Formik
        initialValues={{
          name: data.name ?? "",
          phone: data.phone ?? "",
          isActive: data.isActive ?? false,
          role: data.role_assign ?? false,
          email: data.mail ?? "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values).catch((err) => {
            actions.setStatus(err.message);
            console.log(err.message);
          });
          actions.setFormStatus("submitted");
        }}
      >
        <Form className="form form-label-right" id="updateForm">
          <div className="form-group row">
            <div className="col-6">
              <Field
                disabled={true}
                name="name"
                component={Input}
                placeholder="Name"
                label="Name *"
              />
            </div>
            <div className="col-6">
              <Field
                name="phone"
                component={Input}
                placeholder="Contact number"
                label="Contact Number *"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <Field
                name="email"
                component={Input}
                placeholder="Email"
                label="Email ID *"
              />
            </div>
            <div className="col-lg-6">
              <Select name="isActive" label="Status *">
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Select>
            </div>
          </div>

          {/* <div className="form-group row">
            <div className="col-lg-6">
              <Field
                disabled={true}
                type="password"
                name="password"
                component={Input}
                placeholder="New Password"
                label="New Password *"
              />
            </div>
            <div className="col-lg-6">
              <Field
                disabled={true}
                type="password"
                name="confirmPassword"
                component={Input}
                placeholder="Confirm Password"
                label="Confirm Password *"
              />
            </div>
          </div> */}

          <div className="form-group row">
            <div className="col-lg-12">
              <Select name="role" label="Role *">
                <option value="admin">Administrator</option>
                <option value="manager">Manager</option>
              </Select>
            </div>
          </div>
        </Form>
      </Formik>
    </CardBasic>
  );
};
