import React from "react";

import { BusinessProfile } from "./profile";
import { BillingDetails } from "./billing";
import { useSelector } from "react-redux";


export const ProfilePage = () => {
  const business = useSelector((state) => state.authPersist.user);

  return (
    <>
      <BusinessProfile business={business} />
      {/* <BillingDetails business={business} /> */}
    </>
  );
};
