import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";

import { sortCaret } from "../../_metronic/_helpers";
import { capitalize } from "@material-ui/core";
import { StatusColumnFormatter2 } from "../../_metronic/_helpers/tableColumnFormatter";

import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { loadSubscriptionTransactionsList } from "../../store/admin/subscriptionTransactions/subscriptionTransactionsSlice";
import dayjs from "dayjs";

export const SubscriptionTransactionsTable = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.user.user);
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter((el) => el.countryCode === user.countryCode);
  const subscriptionTransactions = useSelector((state) => state.subscriptionTransactions.subscriptionTransactions);
  //console.log(subscriptionTransactions);
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(loadSubscriptionTransactionsList()).then(() => {
      setDataLoading(false);
    });
  }, []);

  const firstLetterCapital = (cell, row) => {
    //console.log(cell);
    if ( cell === undefined ) {
      return 'N/A'
    } else if (cell === '' ) {
      return 'N/A'
    } else {
      return capitalize(cell);
    }
  };

  const DateFormatter = (cell, row) => {
    return `${dayjs(`${cell}`).format("DD-MM-YYYY h:mm:ss A")}`;
  };

  const AmountFormatter = (cell, row) => {
    return cell / 100;
  };

  const columns = [
    {
      dataField: "",
      text: "#",
      formatter: (cell, row, index) => index+1,
    },
    {
      dataField: "date",
      text: "Date",
      formatter: DateFormatter,
    },
    {
      dataField: "transactionId",
      text: "Order Id",
      //sort: true,
      sortCaret: sortCaret,
      //formatter: DateFormatter,
    },
    {
      dataField: "Business[0].business_name",
      text: "Business Name",
      sort: true,
      sortCaret: sortCaret,
      //formatter: DateFormatter,
    },
    {
      dataField: "amount",
      text: `Amount (${currencySymbol[0] ? currencySymbol[0].currencySymbol : '₹'})`,
      sort: true,
      formatter: AmountFormatter,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "currency",
    //   text: "Currency",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "cycle",
      text: "Cycle",
      sort: true,
      formatter: firstLetterCapital,
      sortCaret: sortCaret,
    },
    {
      dataField: "Subscription[0].name",
      text: "Subscription Name",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: StatusColumnFormatter2,
    }
  ];

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search keyField="uuid" data={subscriptionTransactions} columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>

          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
