import React from "react";
import { BannerTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BannerPage() {
  return (
    <CardBasic
      title={"Banner Table"}
       btnType={"button"}
       headerBtnText={"Add Banner"}
       headerBtnLink={"/banner/create"}
    >
      <BannerTable />
    </CardBasic>
  );
}