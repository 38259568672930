import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Input, Select } from "../../_metronic/_partials/controls";

import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";
import { CardBasic } from "../../components/CardBasic";
import { useDispatch } from "react-redux";
import { uploadBannerImage } from "../../store/admin/banner/bannerSlice";

export const BannerCreate = () => {
  const [formStatus, setFormStatus] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  // const FILE_SIZE = 200 * 200;
  // const SUPPORTED_FORMATS = [
  //   "image/jpg",
  //   "image/jpeg",
  //   "image/gif",
  //   "image/png",
  // ];

  const ValidationSchema = Yup.object().shape({
    banner: Yup.number().required("This field is required"),
    image: Yup.mixed().required("A file is required"),
    couponCode: Yup.string(),
    branchEmail: Yup.string().email("Enter valid email address"),
    city: Yup.string(),
  });

  return (
    <CardBasic
      title={"Add Banner"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save Banner"}
    >
      <Formik
        initialValues={{
          couponCode: "",
          banner: 0,
          image: "",
          branchEmail: '',
          city: ''
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions, errors) => {
          //console.log(values, errors);
          dispatch(uploadBannerImage(values))
            .then((response) => {
             // console.log(response);
              history.replace("/banners");
            })
            .catch((err) => {
              //actions.setStatus("Error! Duplicate Segment Name.");
              dispatch(sendFailure("You have reached the limit. Kindly delete the banner to upload more"));
             // console.log(err.message);
            });
          setFormStatus("submitted");
        }}
      >
        {(formProps) => (
          <Form className="form form-label-right" id="createForm">
            {formProps.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formProps.status}
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-lg-8">
                <label>Banner Image *</label>
                <input
                  type="file"
                  name="image"
                  id="file-input"
                  className="form-control"
                  //multiple
                  onChange={(e) => {
                   // console.log(e.target.files[0]);

                    let file = e.target.files[0];
                    //console.log(file.type);
                    if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                      if (file.size <= 1000000) {
                        formProps.setFieldValue("image", e.target.files[0]);
                      } else {
                        formProps.setFieldError(
                          "image",
                          "Image size should be less than 1 MB"
                        );
                      }
                    } else {
                      formProps.setFieldError(
                        "image",
                        "Image type should be JPG, PNG or JPEG"
                      );
                    }

                  }}
                />
                {formProps.errors.image && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.image}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <Select name="banner" label="Banner Name *">
                  <option value=""></option>
                  <option value={1}>Banner 1</option>
                  <option value={2}>Banner 2</option>
                  <option value={3}>Banner 3</option>
                </Select>
                {formProps.errors.bannerName && (
                  <div className="invalid-feedback d-flex">
                    {formProps.errors.bannerName}
                  </div>
                )}
              </div>
            </div>

            {formProps.values.banner === '1' && <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="couponCode"
                  type="text"
                  component={Input}
                  placeholder="Coupon Code"
                  label="Coupon Code"
                />
              </div>
              <div className="col-lg-6">
              <Field
                  name="branchEmail"
                  type="email"
                  component={Input}
                  placeholder="example@domain.com"
                  label="Branch Email"
                />
              </div>
            </div>}
            {formProps.values.banner === '1' && <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="city"
                  type="text"
                  component={Input}
                  placeholder="city"
                  label="City"
                />
              </div>
            </div>}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
