/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    console.log(url)
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/subscription", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Subscriptions</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/segments", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/segments">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">Business Segment</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/service/categories",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/service/categories">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")}
              />
            </span>
            <span className="menu-text">Service Categories</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/settings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Businesses">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag1.svg")} />
            </span>
            <span className="menu-text">Business Users</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/services", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")}
              />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/userManagement/roles",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
              />
            </span>
            <span className="menu-text">User Management</span>
          </NavLink>
        </li> */}


        {/* <li
          className={`menu-item ${getMenuItemActive("/bookings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/bookings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")} />
            </span>
            <span className="menu-text">Bookings</span>
          </NavLink>
        </li> */}
                    <li
              className={`menu-item menu-item-submenu`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/bookings">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")} />
                </span>
                <span className="menu-text">Bookings</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li className="menu-item  menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Bookings</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/upcoming",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/upcoming">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Upcoming</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/completed",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/completed">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Completed</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bookings/cancelled",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link" to="/bookings/cancelled">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cancelled</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/settings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">Settings</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/bookingTransactions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/bookingTransactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")} />
            </span>
            <span className="menu-text">Booking Transactions</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/subscriptionTransactions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptionTransactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet3.svg")} />
            </span>
            <span className="menu-text">Subscription Transactions</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/banner", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/banners">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")} />
            </span>
            <span className="menu-text">Banner</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/coupons", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/coupons">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Coupons</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/booking/coupons", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/booking/coupons">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Booking Coupons</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/coupons/subscription">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")} />
            </span>
            <span className="menu-text">Coupons</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Coupons</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/coupons/subscription", true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/coupons/subscription">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Subscription Coupons</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/coupons/booking",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/coupons/booking">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Booking Coupons</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/loyaltyPoints", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/loyaltyPoints">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Loyalty Points</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/staff">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Loyalty Points</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Loyalty Points</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/business/loyaltyPoints", true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/business/loyaltyPoints">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Business</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/customer/loyaltyPoints",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/customer/loyaltyPoints">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Customer</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
