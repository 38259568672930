import React from "react";
import { BusinessUserTable } from "./table";
import { CardBasic } from "../../components/CardBasic";

export function BusinessUserPage() {
  return (
    <CardBasic
      title={"Business Users"}
      btnType={"button"}
      headerBtnText={"New Business"}
      headerBtnLink={"/businesses/create"}
    >
      <BusinessUserTable />
    </CardBasic>
  );
}
