import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import { sortCaret } from "../../_metronic/_helpers";
import { StatusColumnFormatter } from "../../_metronic/_helpers/tableColumnFormatter";
import { useDispatch } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
const LinkFormatter = (cell, row) => {
  return <Link to={`/user/:uuid`}>{cell}</Link>;
};
export const RoleCreationTable = () => {
  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    setDataLoading(false);
  }, []);
  const data = [
    {
      id: "1",
      name: "demo",
      role_assign: "Branch Manager",
      isActive:"true",
      phone:"9898989898",
      email:"demo@gmail.com"
    },
  ];
  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (row, cell, index) => {
        return index + 1;
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
        formatter: LinkFormatter,
      // filter: textFilter(),
    },
    {
      dataField: "role_assign",
      text: "Role Assigned",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
    },
  
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
    },
    // {
    //   dataField: "",
    //   text: "Actions",
    //   formatter: ActionColumnSimple,
    // },
  ];
  const { SearchBar, ClearSearchButton } = Search;

  return (
    <ToolkitProvider search data={data} keyField="uuid" columns={columns}>
      {(props) => (
        <>
          <div className="form-group row align-items-center ">
            <div className="col-lg-4">
              <h4 className="mb-0 float-right">Search</h4>
            </div>
            <div className="col-lg-8">
              <SearchBar {...props.searchProps} />
              <ClearSearchButton
                {...props.searchProps}
                className="ml-4 btn btn-secondary-light"
              />
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            wrapperClasses="table-responsive"
            bordered={false}
            bootstrap4
            loading={true}
            pagination={paginationFactory()}
            filter={filterFactory()}
            noDataIndication={
              <div className={"d-flex justify-content-center py-10"}>
                <h3>{dataLoading ? "Loading Data..." : "No records found"}</h3>
              </div>
            }
          />
        </>
      )}
    </ToolkitProvider>
  );
};
