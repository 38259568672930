import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/admin/banner";
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getBannerList = () => {
  return axios.get(`${URL}s`);
};

export const uploadBanner = (data) => {
 //console.log(data);
  const req = new FormData();

  req.append("banner", data.banner);
  req.append("image", data.image);
  req.append("branchEmail", data.branchEmail);
  req.append("city", data.city);
  req.append("couponCode", data.couponCode);
  for (const value of req.values()) {
    //console.log(value);
  }
  return axios.post(`${URL}/create`, req, {
    headers,
  });
};
export const editBanner = (data) => {
  //console.log(data);
   const req = new FormData();
 
   req.append("banner", data.banner);
   req.append("image", data.image);
   req.append("branchEmail", data.branchEmail);
   req.append("city", data.city);
   req.append("couponCode", data.couponCode);
   req.append("uuid", data.uuid);
   for (const value of req.values()) {
     //console.log(value);
   }
   return axios.patch(`${URL}/update`, req, {
     headers,
   });
 };
export const deleteBannerImg = (uuid) => {
  //console.log(uuid);
  const req = {
    uuid: uuid,
  }
  //console.log(req);
  return axios.delete(`${URL}/delete`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      "uuid": uuid
    }
  });
};
