import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
  Checkbox2,
} from "../../_metronic/_partials/controls";
import { CardBasic } from "../../components/CardBasic";
import { createCoupon } from "../../store/admin/coupon/couponSlice";
import { getBusinessListData } from "../../store/admin/business/businessSlice";

export const CouponCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(true);
  let Business = useSelector((state) => state.business.business);
  useEffect(() => {
    dispatch(getBusinessListData()).then(() => {
      setDataLoading(false);
    });
  }, []);
  console.log(Business);
  const validationSchema = Yup.object().shape({
    couponCode: Yup.string().required("This field is required"),
    // discount: Yup.number()
    //   .min(1)
    //   .required("This field is required")
    //   .when("discountType", {
    //     is: 1,
    //     then: Yup.number().max(100),
    //   }),
    description: Yup.string().required("This field is required"),
    discount: Yup.number()
      .min(1)
      .max(100)
      .required("This field is required"),
    startDate: Yup.string().required("This field is required"),
    expiryDate: Yup.string().required("This field is required"),
    minimumAmount: Yup.number()
      .min(1)
      .required("This field is required"),
  });

  return (
    <CardBasic
      title={"Add Subscription Coupon"}
      btnType={"submit"}
      formId={"createForm"}
      headerBtnText={"Save Coupon"}
    >
      <Formik
        initialValues={{
          couponCode: "",
          discount: 1,
          startDate: "",
          expiryDate: "",
          minimumAmount: 1,
          description: "",
          singleUseCheck: false,
          termAndConditions:"",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          //console.log(values);
          // if (values.singleUseCheck === false) {
          //   values.singleUseCheck = 0;
          // } else {
          //   values.singleUseCheck = 1;
          // }
          dispatch(createCoupon(values))
            .then(() => {
              //console.log("here");
              history.replace("/coupons/subscription");
            })
            .catch((err) => {
              actions.setStatus("Error! Duplicate coupon.");
              console.log(err.message);
            });
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form form-label-right" id="createForm">
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="couponCode"
                  type="text"
                  component={Input}
                  placeholder="Coupon Code"
                  label="Coupon Code *"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  name="discount"
                  type="number"
                  min="1"
                  component={Input}
                  placeholder="Discount"
                  label="Discount *"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <div>
                  <label>Start Date *</label>
                </div>
                <div className="w-100">
                  <DatePickerField
                    name="startDate"
                    className="form-control"
                    minDate={new Date()}
                    format="dd/mm/yyyy"
                    value={values.startDate}
                    onChange={(val) => {
                      setFieldValue("startDate", val);
                    }}
                  ></DatePickerField>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label>Expiry Date *</label>
                </div>
                <div className="w-100">
                  <DatePickerField
                    name="expiryDate"
                    className="form-control"
                    minDate={new Date()}
                    format="dd/mm/yyyy"
                    value={values.expiryDate}
                    onChange={(val) => {
                      setFieldValue("expiryDate", val);
                    }}
                  ></DatePickerField>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <Field
                  name="minimumAmount"
                  type="number"
                  min="1"
                  component={Input}
                  label="Minimum Amount *"
                />
              </div>
              <div className="col-lg-6">
                <Checkbox2
                  name="singleUseCheck"
                  isSelected={values.singleUseCheck}
                  onChange={(val) => {
                    setFieldValue("singleUseCheck", val.target.checked);
                  }}
                  children={`Single Use Only`}
                />
                {/* <label className="checkbox">
                 
                  <span></span>
                  Default
                  <input type="checkbox" name="singleUseCheck" onChange={(val) => {
                    setFieldValue("singleUseCheck", val.target.checked);
                  }} />
                </label> */}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="description"
                  component={Input}
                  label="Description *"
                />
              </div>
            </div>
            <div className="form-group row">
                <div className="col-lg-12">
                <label>Terms & Conditions</label>
                  <Field
                    name="termAndConditions"
                    type="textarea"
                    // component={Input}
                    as="textarea"
                    rows="2"
                    label="Terms & Conditions"
                    className="form-control"
                  />
                </div>
              </div>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </CardBasic>
  );
};
